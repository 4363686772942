export type StorageKeys = "accessToken" | "communityId" | "clientToken";

export class StorageService {
  getItem = <T = any>(key: StorageKeys): T => {
    const value = localStorage.getItem(key);
    return JSON.parse(value as string);
  };

  setItem = (key: StorageKeys, value: string | Object | number): void => {
    const stringifyValue: string = JSON.stringify(value);
    localStorage.setItem(key, stringifyValue);
  };

  removeItem = (key: StorageKeys): void => {
    localStorage.removeItem(key);
  };

  clear = (): void => {
    localStorage.clear();
  };
}

export default new StorageService();
