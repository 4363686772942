import { createAsyncThunk } from '@reduxjs/toolkit'

import type { RootState } from '../index'
import api from 'api'

import {
  UpdateRoomNameData,
  UpdateRoomLogoData,
  UpdateRoomBioData,
  ListChatRoomsOfUserRequestData,
  GetChatRoomDetailData,
  RemoveUsersFromChatsRoomData,
  UsersListOfChatRoomDataRequest,
  CreateChatRoomRequestBody,
  PinningChatRoomRequestBody,
} from 'api/models/chat'

//import { setisFetchingChatRooms } from '../reducers/chatrooms'

export const getChatRooms = createAsyncThunk(
  'chatrooms/getChatRooms',
  async (body: ListChatRoomsOfUserRequestData, { rejectWithValue }) => {
    try {
      const res = await api.chat.getListChatRoomsOfUser(body)
      return res.data.data
    } catch (error) {
      return rejectWithValue('something went wrong')
    }
  }
)

export const getChannelUsers = createAsyncThunk(
  'chatrooms/getChannelUsers',
  async (body: UsersListOfChatRoomDataRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.community.getChannelUsersList(body)
      return data.data
    } catch (error) {
      return rejectWithValue('something went wrong')
    }
  }
)

export const getChatRoomDetailById = createAsyncThunk(
  'chatrooms/getChatRoomDetailById',
  async (body: GetChatRoomDetailData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getChatRoomDetailById(body)
      return data.data
    } catch (error) {
      return rejectWithValue('Something went wrong')
    }
  }
)

export const getChatRoomUsersList = createAsyncThunk(
  'chatrooms/getChatRoomUsersList',
  async (body: GetChatRoomDetailData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getUsersListOfChatRoom(body)
      return data.data
    } catch (error) {
      return rejectWithValue('Something went wrong')
    }
  }
)

export const updateChatRoomName = createAsyncThunk(
  'chatrooms/updateChatRoomName',
  async (body: UpdateRoomNameData, { rejectWithValue, dispatch }) => {
    try {
      //dispatch(setisFetchingChatRooms(true))
      await api.chat.chatUpdateRoomName(body)
      dispatch(getChatRoomDetailById({ chatRoomId: body.chatRoomId }))
    } catch (error) {
      //dispatch(setisFetchingChatRooms(false))
      return rejectWithValue('Something went wrong')
    }
  }
)

export const updateChatRoomBio = createAsyncThunk(
  'chatrooms/updateChatRoomBio',
  async (body: UpdateRoomBioData, { rejectWithValue, dispatch }) => {
    try {
      //dispatch(setisFetchingChatRooms(true))
      await api.chat.chatUpdateRoomBio(body)
      dispatch(getChatRoomDetailById({ chatRoomId: body.chatRoomId }))
    } catch (error) {
      //dispatch(setisFetchingChatRooms(false))
      return rejectWithValue('Something went wrong')
    }
  }
)

interface UpdateChatRoomLogoData {
  chatRoomId: string
  formData: FormData
}

export const updateChatRoomLogo = createAsyncThunk(
  'chatrooms/updateChatRoomLogo',
  async (body: UpdateChatRoomLogoData, { rejectWithValue, dispatch }) => {
    try {
      //dispatch(setisFetchingChatRooms(true))
      await api.chat.chatUpdateRoomLogo(body.formData)
      dispatch(getChatRoomDetailById({ chatRoomId: body.chatRoomId }))
    } catch (error) {
      //dispatch(setisFetchingChatRooms(false))
      return rejectWithValue('Something went wrong')
    }
  }
)

export const removeParticipantFromChatRoom = createAsyncThunk(
  'chatrooms/removeParticipantFromChatRoom',
  async (body: RemoveUsersFromChatsRoomData, { rejectWithValue, dispatch }) => {
    try {
      await api.chat.removeUsersFromChatRooms(body)
      dispatch(getChatRoomUsersList({ chatRoomId: body.chatRoomId }))
    } catch (error) {
      return rejectWithValue('something went wrong')
    }
  }
)

export const createChatRoom = createAsyncThunk(
  'chatrooms/createChatRoom',
  async (body: CreateChatRoomRequestBody, { rejectWithValue }) => {
    try {
      await api.chat.createChatRoom(body)
    } catch (error) {
      return rejectWithValue('failed create a chat room')
    }
  }
)

export const pinningChatRoom = createAsyncThunk(
  'chatrooms/pinningChatRoom',
  async (
    body: PinningChatRoomRequestBody,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      await api.chat.pinningChatRoom(body)

      const { profile, channels } = getState() as RootState

      const userId = profile.profileData.userId
      const channelId = channels.activeChannelIdentifier

      if (userId && channelId) {
        dispatch(getChatRooms({ userId, channelId }))
      }
    } catch (error) {
      return rejectWithValue('')
    }
  }
)

export const unpinningChatRoom = createAsyncThunk(
  'chatrooms/pinningChatRoom',
  async (
    body: PinningChatRoomRequestBody,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      await api.chat.unpinningChatRoom(body)
      const { profile, channels } = getState() as RootState

      const userId = profile.profileData.userId
      const channelId = channels.activeChannelIdentifier

      if (userId && channelId) {
        dispatch(getChatRooms({ userId, channelId }))
      }
    } catch (error) {
      return rejectWithValue('')
    }
  }
)
