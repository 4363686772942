import { useEffect, useState, useCallback } from 'react'
import { useParams, Params } from 'react-router-dom'

import TopNavigationLayout from 'components/TopNavigationLayout'
import PageLayout from 'components/PageLayout'
import BackButton from 'components/BackButton'
import SettingsChatRoom from 'components/SettingsChatRoom'
import ParticipantsChatRoom from 'components/ParticipantsChatRoom'
import {
  getChatRoomsParticipantsSelector,
  clearChatRoomParticipant,
  getChatRoomNameSelector,
  getChatRoomLogoSelector,
  getChatRoomBioSelector,
  getOrganizationUuidSelector,
} from 'store/reducers/chatrooms'
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from 'hooks/redux'
import {
  removeParticipantFromChatRoom,
  updateChatRoomBio,
  updateChatRoomLogo,
  updateChatRoomName,
} from 'store/async-actions/chatrooms'
import { getIsModeratorChannelSelector } from 'store/reducers/channels'

import { getChatRoomUsersList } from 'store/async-actions/chatrooms'

const ChatRoomSettingsPage = () => {
  const { roomId } = useParams<Params<'roomId'>>()

  const dispatch = useAppDispatch()

  const participants = useAppShallowSelector(getChatRoomsParticipantsSelector)
  const chatRoomNameStore = useAppSelector(getChatRoomNameSelector)
  const chatRoomBioStore = useAppSelector(getChatRoomBioSelector)
  const chatRoomLogo = useAppSelector(getChatRoomLogoSelector)
  const organizationUuid = useAppSelector(getOrganizationUuidSelector)
  const isModerator = useAppSelector(getIsModeratorChannelSelector)

  const [chatRoomName, setChatRoomName] = useState<string>(chatRoomNameStore)
  const [chatRoomBio, setChatRoomBio] = useState<string>(chatRoomBioStore)
  const [selectedState, setSelectedState] = useState<UserId[]>([])

  const handleRemovePartisipants = useCallback(
    (ids: UserId[]) => {
      const userIds = ids.map((id) => ({ userId: id, isPinned: false }))

      dispatch(
        removeParticipantFromChatRoom({
          chatRoomId: roomId!,
          userIds: userIds,
        })
      )
    },
    [dispatch, roomId]
  )

  const handleSubmit = useCallback(() => {
    if (roomId) {
      dispatch(updateChatRoomBio({ chatRoomBio, chatRoomId: roomId! }))
    }
  }, [chatRoomBio, dispatch, roomId])

  const handleSubmitNewRoomName = useCallback(() => {
    if (roomId) {
      dispatch(updateChatRoomName({ chatRoomId: roomId, chatRoomName }))
    }
  }, [chatRoomName, dispatch, roomId])

  const handleChangeLogo = useCallback(
    (file: File) => {
      if (roomId) {
        const formData = new FormData()
        formData.append('chatRoomId', roomId)
        formData.append('organizationUuid', `${organizationUuid}`)
        formData.append('file', file)
        dispatch(
          updateChatRoomLogo({
            chatRoomId: roomId,
            formData: formData,
          })
        )
      }
    },
    [dispatch, organizationUuid, roomId]
  )

  useEffect(() => {
    if (chatRoomNameStore) {
      setChatRoomName(chatRoomNameStore)
    }
  }, [chatRoomNameStore])

  useEffect(() => {
    if (chatRoomBioStore) {
      setChatRoomBio(chatRoomBioStore)
    }
  }, [chatRoomBioStore])

  useEffect(() => {
    if (roomId) {
      dispatch(getChatRoomUsersList({ chatRoomId: roomId }))
    }
    return () => {
      dispatch(clearChatRoomParticipant())
    }
  }, [dispatch, roomId])

  return (
    <>
      <TopNavigationLayout>
        <BackButton title="Settings" />
      </TopNavigationLayout>
      <PageLayout className="chat_room_settings_page">
        <SettingsChatRoom
          roomId={roomId!}
          isOwner={isModerator}
          isCreate={false}
          nameValue={chatRoomName}
          bioValue={chatRoomBio}
          logo={chatRoomLogo}
          changeNameValue={setChatRoomName}
          changeBioValue={setChatRoomBio}
          changeLogo={handleChangeLogo}
          submitBio={handleSubmit}
          submitName={handleSubmitNewRoomName}
        />
        <ParticipantsChatRoom
          data={participants}
          isOwner={isModerator}
          selectedState={selectedState}
          changeSelectedState={setSelectedState}
          deleteParticipanst={handleRemovePartisipants}
          withDeleteConfirmation={true}
        />
      </PageLayout>
    </>
  )
}

export default ChatRoomSettingsPage
