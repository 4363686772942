import React, { useEffect } from "react";
import { Outlet, useParams, Params } from "react-router-dom";

import BottomNavMenu from "components/BottomNavMenu";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getChannels } from "store/async-actions/channels";
import { getCommunityIdSelector } from "store/reducers/profile";
import { useAnalytics } from "hooks/useAnalytics";

const MainLayout = () => {
  useAnalytics();
  const dispatch = useAppDispatch();

  const { roomId = "" } = useParams<Params<"roomId">>();

  const communityId = useAppSelector(getCommunityIdSelector);

  useEffect(() => {
    if (communityId) {
      dispatch(getChannels({ communityId }));
    }
  }, [dispatch, communityId]);

  return (
    <div className="main_container">
      <Outlet />
      {!roomId ? <BottomNavMenu /> : null}
    </div>
  );
};

export default React.memo(MainLayout);
