import React from "react";

import Text from "components/common/Text";
import { getActiveVideoSelector } from "store/reducers/content";
import { useAppShallowSelector } from "hooks/redux";
import { getChannelTabsSelector } from "store/reducers/channels";

import Tabs, { Tab } from "components/common/Tabs";

export enum TabValues {
  ATTR1 = "attribute1",
  ATTR2 = "attribute2",
  ATTR3 = "attribute3",
}

export type TabValuesType = "attribute1" | "attribute2" | "attribute3";

const TabsPanel = () => {
  const activeVideo = useAppShallowSelector(getActiveVideoSelector);
  const channelTabs = useAppShallowSelector(getChannelTabsSelector);

  const [activeTab, setActiveTab] = React.useState<TabValuesType>(
    (channelTabs?.at(0)?.tabType as TabValuesType) || TabValues.ATTR1
  );

  const handleChangeActiveTab = (
    _event: React.SyntheticEvent,
    newValue: TabValues
  ) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChangeActiveTab}
        variant="scrollable"
      >
        {channelTabs.map((tab, index) => {
          return <Tab key={index} value={tab.tabType} label={tab.tabName} />;
        })}
      </Tabs>
      <ActiveTabPanel contents={activeVideo ? activeVideo[activeTab] : []} />
    </>
  );
};

type ATTR2 = {
  description: string;
  title: string;
};

interface ActiveTabPanelProps {
  contents: string[] | ATTR2[];
}

const ActiveTabPanel: React.FC<ActiveTabPanelProps> = ({ contents = [] }) => {
  return (
    <div className="active_tab_panel_container">
      {contents.map((item, index) => {
        if (typeof item === "object") {
          return (
            <span className="active_tab_panel_content_container" key={index}>
              <Text className="active_tab_panel_content active_tab_panel_content_title">
                {`${item.title}.`}
              </Text>
              <Text className="active_tab_panel_content active_tab_panel_content_description">
                {item.description}
              </Text>
            </span>
          );
        }
        return (
          <Text className="active_tab_panel_content" key={index}>
            {item}
          </Text>
        );
      })}
    </div>
  );
};

export default TabsPanel;
