import BaseApi from "api/base";
import { AnalyticsCTARequestData } from "api/models/analytics";
import { BaseResponse } from "api/models/base";
import { AxiosInstance, AxiosPromise } from "axios";

export class Analytics extends BaseApi {
  fetchCTALogs(data: AnalyticsCTARequestData): AxiosPromise<BaseResponse> {
    return this.request({
      url: `${this.url}/cta/auth/update/logs`,
      method: "POST",
      data,
    });
  }

  startVideo(data: {
    video: string;
    userId: string;
    durationSeconds: number;
    currentSeconds: number;
    channelId?: number;
    communityId?: number;
    ip?: string;
  }): AxiosPromise<BaseResponse> {
    return this.request({
      url: `${this.url}/analytics/startVideo`,
      method: "POST",
      data,
    });
  }

  stopVideo(data: {
    video: string;
    userId: string;
    durationSeconds: number;
    currentSeconds: number;
    channelId?: number;
    communityId?: number;
    ip?: string;
  }): AxiosPromise<BaseResponse> {
    return this.request({
      url: `${this.url}/analytics/stopVideo`,
      method: "POST",
      data,
    });
  }

  completedVideo(data: {
    video: string;
    userId: string;
    durationSeconds: number;
    currentSeconds: number;
    channelId?: number;
    communityId?: number;
    ip?: string;
  }): AxiosPromise<BaseResponse> {
    return this.request({
      url: `${this.url}/analytics/completedVideo`,
      method: "POST",
      data,
    });
  }
}

export default function analyticsApi(request: AxiosInstance) {
  return new Analytics({
    url: "/spring/analytics",
    request,
  });
}
