import { useEffect } from "react";

import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from "hooks/redux";
import PageLayout from "components/PageLayout";
import TitleSearchSections from "./components/TitleSearchSections";
import ChatRoomsListSections from "./components/ChatRoomsListSections";
import FlatActionButton from "components/FlatActionButton";
import Loader from "components/common/Loader";
import { getChatRooms } from "store/async-actions/chatrooms";
import {
  getFilteredChatRoomsByIsPinnedSelector,
  getIsFetchingChatRoomsSelector,
} from "store/reducers/chatrooms";
import { getProfileUserIdSelector } from "store/reducers/profile";
import {
  getActiveChannelIdentifierSelector,
  getIsModeratorChannelSelector,
} from "store/reducers/channels";

export interface Chatroom {
  id: UniqueId;
  title: string;
  description: string;
  logo: Logo;
  isPinned: boolean;
  onlineCount: number;
}

const ChatRoomsPage = () => {
  const dispatch = useAppDispatch();

  const userId = useAppSelector(getProfileUserIdSelector);
  const channelId = useAppSelector(getActiveChannelIdentifierSelector);
  const isModerator = useAppSelector(getIsModeratorChannelSelector);

  const { pinned, unpinned } = useAppShallowSelector(
    getFilteredChatRoomsByIsPinnedSelector
  );

  useEffect(() => {
    if (userId && channelId !== null) {
      dispatch(
        getChatRooms({
          userId: userId, //1746, //////// remove mock userId and pass userId from store (profile reducers)
          channelId,
        })
      );
    }
  }, [channelId, dispatch, userId]);

  return (
    <>
      <PageLayout className="chatrooms_page">
        <TitleSearchSections />
        {!!pinned.length && (
          <ChatRoomsListSections title="Pinned" dataList={pinned} />
        )}
        {!!unpinned.length && (
          <ChatRoomsListSections title="Rooms" dataList={unpinned} />
        )}
      </PageLayout>
      {isModerator && <FlatActionButton />}
      <PageLoader />
    </>
  );
};

const PageLoader = () => {
  const isFetching = useAppSelector(getIsFetchingChatRoomsSelector);
  return isFetching ? <Loader /> : null;
};

export default ChatRoomsPage;
