import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { createTypedSelector } from "store/utils";

import {
  getChatRooms,
  getChatRoomDetailById,
  updateChatRoomBio,
  updateChatRoomLogo,
  updateChatRoomName,
  getChatRoomUsersList,
  removeParticipantFromChatRoom,
  getChannelUsers,
  createChatRoom,
} from "../async-actions/chatrooms";

import { getProfileUserIdSelector } from "../reducers/profile";

import { ChatRoom } from "api/models/chat";
import { ChannelUser } from "api/models/community";
import { ProfileResponce } from "api/models/profile";

export interface ChatroomForComponent {
  id: UniqueId;
  title: string;
  description: string;
  logo: Logo;
  isPinned: boolean;
  onlineCount: number;
}
export interface FilteredChatRoomsByPinned {
  pinned: ChatroomForComponent[];
  unpinned: ChatroomForComponent[];
}
export interface Participant {
  foto: Logo;
  name: UserName;
  nikName: UserName;
  id: UserId;
}

export interface ChatRoomCreateValues {
  chatRoomName: string;
  chatRoomBio: string;
}

interface InitialState {
  chantroomsList: ChatRoom[];
  chatRoomDetail: ChatRoom;
  isFetchingChatrooms: boolean;
  chatRoomParticipants: ProfileResponce[];
  createSelectedState: UserId[];
  searchRoomsValue: string;
  channelUsers: ChannelUser[];
  createValues: ChatRoomCreateValues;
}

const initialState: InitialState = {
  chantroomsList: [],
  chatRoomDetail: {} as ChatRoom,
  isFetchingChatrooms: false,
  chatRoomParticipants: [],
  createSelectedState: [],
  searchRoomsValue: "",
  channelUsers: [],
  createValues: {
    chatRoomName: "",
    chatRoomBio: "",
  },
};

const chatRoomsSlice = createSlice({
  name: "chatrooms",
  initialState,
  reducers: {
    setChatRoomsList: (state, action: PayloadAction<ChatRoom[]>) => {
      state.chantroomsList = action.payload;
    },
    setisFetchingChatRooms: (state, action: PayloadAction<boolean>) => {
      state.isFetchingChatrooms = action.payload;
    },
    setCreateSelectedState: (state, action: PayloadAction<UserId[]>) => {
      state.createSelectedState = action.payload;
    },
    clearChatRoomDetail: (state) => {
      state.chatRoomDetail = initialState.chatRoomDetail;
    },
    clearChatRoomParticipant: (state) => {
      state.chatRoomParticipants = initialState.chatRoomParticipants;
    },
    clearCreateChatRoom: (state) => {
      state.channelUsers = initialState.channelUsers;
      state.createSelectedState = initialState.createSelectedState;
      state.createValues = initialState.createValues;
    },
    setSearchRoomsValue: (state, action: PayloadAction<string>) => {
      state.searchRoomsValue = action.payload;
    },
    setChatRoomName: (state, action: PayloadAction<string>) => {
      state.createValues.chatRoomName = action.payload;
    },
    setChatRoomBio: (state, action: PayloadAction<string>) => {
      state.createValues.chatRoomBio = action.payload;
    },
    clearCreateValues: (state) => {
      state.createValues = initialState.createValues;
    },
  },
  extraReducers: {
    [getChatRooms.pending.type]: (state) => {
      state.isFetchingChatrooms = true;
    },
    [getChatRooms.fulfilled.type]: (
      state,
      action: PayloadAction<ChatRoom[]>
    ) => {
      state.isFetchingChatrooms = false;
      state.chantroomsList = action.payload;
    },
    [getChatRooms.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },

    [getChatRoomDetailById.pending.type]: (state) => {
      //state.isFetchingChatrooms = true
    },
    [getChatRoomDetailById.fulfilled.type]: (
      state,
      action: PayloadAction<ChatRoom>
    ) => {
      state.isFetchingChatrooms = false;
      state.chatRoomDetail = action.payload;
    },
    [getChatRoomDetailById.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },

    [updateChatRoomName.pending.type]: (state) => {
      state.isFetchingChatrooms = true;
    },
    [updateChatRoomName.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },

    [updateChatRoomBio.pending.type]: (state) => {
      state.isFetchingChatrooms = true;
    },
    [updateChatRoomBio.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },

    [updateChatRoomLogo.pending.type]: (state) => {
      state.isFetchingChatrooms = true;
    },
    [updateChatRoomLogo.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },

    [getChatRoomUsersList.pending.type]: (state) => {
      state.isFetchingChatrooms = true;
    },
    [getChatRoomUsersList.fulfilled.type]: (
      state,
      action: PayloadAction<ProfileResponce[]>
    ) => {
      state.chatRoomParticipants = action.payload;
      state.isFetchingChatrooms = false;
    },
    [getChatRoomUsersList.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },
    [removeParticipantFromChatRoom.pending.type]: (state) => {
      state.isFetchingChatrooms = true;
    },
    [removeParticipantFromChatRoom.fulfilled.type]: (state) => {
      state.isFetchingChatrooms = false;
    },
    [removeParticipantFromChatRoom.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },
    [getChannelUsers.pending.type]: (state) => {
      state.isFetchingChatrooms = true;
    },
    [getChannelUsers.fulfilled.type]: (
      state,
      action: PayloadAction<ChannelUser[]>
    ) => {
      state.isFetchingChatrooms = false;
      state.channelUsers = action.payload;
    },
    [getChannelUsers.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },
    [createChatRoom.pending.type]: (state) => {
      state.isFetchingChatrooms = true;
    },
    [createChatRoom.fulfilled.type]: (state) => {
      state.isFetchingChatrooms = false;
    },
    [createChatRoom.rejected.type]: (state) => {
      state.isFetchingChatrooms = false;
    },
  },
});

export const getCurrentlyChatRoomIdSelector = createTypedSelector(
  (state) => state.chatrooms.chatRoomDetail._id
);

export const getIsFetchingChatRoomsSelector = createTypedSelector(
  (state) => state.chatrooms.isFetchingChatrooms
);

export const getChatRoomsSelector = createTypedSelector(
  (state) => state.chatrooms.chantroomsList
);

export const getSearchRoomsValueSelector = createTypedSelector(
  (state) => state.chatrooms.searchRoomsValue
);

export const getFilteredChatRoomsByIsPinnedSelector = createSelector(
  getChatRoomsSelector,
  getSearchRoomsValueSelector,
  getProfileUserIdSelector,
  (chatrooms, searchValue, userIdent) => {
    return chatrooms.reduce<FilteredChatRoomsByPinned>(
      (res, room) => {
        if (
          room?.chatRoomName
            ?.toLocaleLowerCase()
            ?.includes(searchValue.toLocaleLowerCase())
        ) {
          const isPinned = room.userIds.some(
            (u) => u.userId === userIdent && u.isPinned
          );

          if (isPinned)
            res.pinned.push({
              id: room._id,
              title: room.chatRoomName,
              description: room.chatRoomBio,
              isPinned: isPinned,
              logo: room.chatRoomLogo,
              onlineCount: room.activeUsers?.length,
            });
          else
            res.unpinned.push({
              id: room._id,
              title: room.chatRoomName,
              description: room.chatRoomBio,
              isPinned: isPinned,
              logo: room.chatRoomLogo,
              onlineCount: room.activeUsers?.length,
            });
        }

        return res;
      },
      {
        pinned: [],
        unpinned: [],
      }
    );
  }
);

export const getChatRoomDetailSelector = createTypedSelector(
  (state) => state.chatrooms.chatRoomDetail
);

export const getChatRoomNameSelector = createTypedSelector(
  (state) => state.chatrooms.chatRoomDetail?.chatRoomName || ""
);

export const getChatRoomBioSelector = createTypedSelector(
  (state) => state.chatrooms.chatRoomDetail?.chatRoomBio || ""
);

export const getChatRoomLogoSelector = createTypedSelector(
  (state) => state.chatrooms.chatRoomDetail?.chatRoomLogo || ""
);

export const getOrganizationUuidSelector = createTypedSelector(
  (state) => state.chatrooms.chatRoomDetail?.organizationUuid
);

export const getChatRoomNameByIdSelector = (id: string) =>
  createTypedSelector((state) => {
    return (
      state.chatrooms.chantroomsList.find((room) => room?._id === id)
        ?.chatRoomName || ""
    );
  });

export const getChatRoomPartisipants = createTypedSelector(
  (state) => state.chatrooms.chatRoomParticipants
);

export const getCreateChatRoomChannelUser = createTypedSelector(
  (state) => state.chatrooms.channelUsers
);

export const getChatRoomsParticipantsSelector = createSelector(
  getChatRoomPartisipants,
  (participants) => {
    return participants.map<Participant>((participant, index) => ({
      id: participant.userId || index,
      name: participant.userName || "John Doe",
      nikName: participant.userName || "John Doe",
      foto: participant.profilePhoto,
    }));
  }
);

export const getCreateChatRoomChannelUserSelector = createSelector(
  getCreateChatRoomChannelUser,
  (participants) => {
    return participants.map<Participant>((participant, index) => ({
      id: Number(participant.userId) || index,
      name: participant.userName || participant.user || "John Doe",
      nikName: participant.user || "",
      foto: participant.profilePhoto || "",
    }));
  }
);

export const getChatRoomByIdSelector = (id: string) =>
  createTypedSelector((state) =>
    state.chatrooms.chantroomsList.find((room) => room._id === id)
  );

export const getParticipantByIdSelector = (id: UserId) =>
  createTypedSelector((state) => {
    return state.chatrooms.channelUsers.find((p) => Number(p.userId) === id);
  });

export const getCreateSelectedStateSelector = createTypedSelector(
  (state) => state.chatrooms.createSelectedState
);

export const getParticipansHowIsSelected = createSelector(
  getCreateChatRoomChannelUserSelector,
  getCreateSelectedStateSelector,
  (participans, selectedIds) => {
    return participans.filter((p) => selectedIds.includes(p.id));
  }
);

export const getChatRoomNameCreateValueSelector = createTypedSelector(
  (state) => state.chatrooms.createValues.chatRoomName
);

export const getChatRoomBioCreateValueSelector = createTypedSelector(
  (state) => state.chatrooms.createValues.chatRoomBio
);

export const {
  setChatRoomsList,
  setisFetchingChatRooms,
  setCreateSelectedState,
  clearChatRoomDetail,
  clearChatRoomParticipant,
  clearCreateChatRoom,
  setSearchRoomsValue,
  setChatRoomBio,
  setChatRoomName,
  clearCreateValues,
} = chatRoomsSlice.actions;

export default chatRoomsSlice.reducer;
