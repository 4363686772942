import React from 'react'

import Text from 'components/common/Text'

import NoPostsIcon from 'components/common/svg/NoPostsIcon'
import { useAppSelector } from 'hooks/redux'
import { getIsLoadingContentSelector } from 'store/reducers/content'
import { getIsLoadingChannelsSelector } from 'store/reducers/channels'

const EmptyContent = () => {
  const isLoadingContent = useAppSelector(getIsLoadingContentSelector)
  const isLoadingChannel = useAppSelector(getIsLoadingChannelsSelector)

  if (isLoadingChannel || isLoadingContent) return null

  return (
    <div className="empty_content">
      <NoPostsIcon className="empty_content_icon" />
      <Text className="empty_content_text">No posts to show</Text>
    </div>
  )
}

export default EmptyContent
