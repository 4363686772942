import React from 'react'

import Text from 'components/common/Text'
import Input from 'components/common/Input'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import {
  setSearchRoomsValue,
  getSearchRoomsValueSelector,
} from 'store/reducers/chatrooms'

const TitleSearchSections = () => {
  const dispatch = useAppDispatch()

  const searchValue = useAppSelector(getSearchRoomsValueSelector)

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchRoomsValue(event.target.value))
  }

  return (
    <div className="title_search_section">
      <Text className="page_title" fontWeight="w700">
        Chatrooms
      </Text>
      <Input
        placeholder="Search"
        classNameContainer="input_search_container"
        variant="ountlined"
        onChange={handleChangeSearch}
        value={searchValue}
      />
    </div>
  )
}

export default TitleSearchSections
