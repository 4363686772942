import { AxiosInstance, AxiosPromise } from 'axios'

import BaseApi from 'api/base'
import { BaseResponse } from '../models/base'
import {
  ChannelContentsDataRequest,
  ChannelContentsDataResponse,
} from '../models/content'

export class ContentApi extends BaseApi {
  getChannelContents(
    data: ChannelContentsDataRequest
  ): AxiosPromise<BaseResponse<ChannelContentsDataResponse[]>> {
    return this.request({
      url: `${this.url}/video/List`,
      method: 'post',
      data,
    })
  }
}

export default function contentApi(request: AxiosInstance) {
  return new ContentApi({
    url: '/content',
    request,
  })
}
