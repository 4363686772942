import { memo, forwardRef, AudioHTMLAttributes } from 'react'

const AudioEpisode = forwardRef<
  HTMLAudioElement,
  AudioHTMLAttributes<HTMLAudioElement>
>((props, ref) => {
  return <audio ref={ref} {...props} />
})

export default memo(AudioEpisode)
