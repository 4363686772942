import { AxiosInstance, AxiosPromise } from 'axios'

import BaseApi from 'api/base'
import { BaseResponse } from '../models/base'
import {
  ChannelContentsDataResponse,
  SearchDataRequest,
} from '../models/content'

export class SearchApi extends BaseApi {
  getSearchContents(
    data: SearchDataRequest
  ): AxiosPromise<BaseResponse<ChannelContentsDataResponse[]>> {
    return this.request({
      url: `${this.url}/video/graph`,
      method: 'post',
      data,
    })
  }
}

export default function searchApi(request: AxiosInstance) {
  return new SearchApi({
    url: '/search',
    request,
  })
}
