import React from 'react'
import { Link } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import { Routes, AbsoluteRoutes } from 'routes'

interface MenuButtonProps {
  to: Routes | AbsoluteRoutes
}

const MenuButton: React.FC<MenuButtonProps> = ({ to }) => {
  return (
    <Link className="menu_button" to={to}>
      <MoreHorizIcon fontSize="inherit" />
    </Link>
  )
}

export default MenuButton
