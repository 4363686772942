import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AbsoluteRoutes, Routes } from 'routes'
import PageLayout from 'components/PageLayout'
import TopNavigationLayout from 'components/TopNavigationLayout'
import TopNavigatorLink from 'components/common/TopNavigatorLink'
import Text from 'components/common/Text'
import ParticipantsChatRoom from 'components/ParticipantsChatRoom'

import SettingsChatRoom from 'components/SettingsChatRoom'
import {
  useAppDispatch,
  useAppSelector,
  useAppShallowSelector,
} from 'hooks/redux'
import {
  getParticipansHowIsSelected,
  getCreateSelectedStateSelector,
  setCreateSelectedState,
} from 'store/reducers/chatrooms'
import { createChatRoom } from 'store/async-actions/chatrooms'
import { getActiveChannelIdentifierSelector } from 'store/reducers/channels'
import { getCommunityIdSelector } from 'store/reducers/profile'

const ChatRoomCreatePageSettings = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [roomName, setRoomName] = useState<string>('')
  const [roomBio, setRoomBio] = useState<string>('')

  const participansList = useAppShallowSelector(getParticipansHowIsSelected)
  const channelId = useAppSelector(getActiveChannelIdentifierSelector)
  const communityId = useAppSelector(getCommunityIdSelector)
  const selectedState = useAppShallowSelector(getCreateSelectedStateSelector)

  const setSelectedState = useCallback(
    (newState: UserId[]) => {
      dispatch(setCreateSelectedState(newState))
    },
    [dispatch]
  )

  const handleDeletePartisipan = useCallback(
    ([id]: UserId[]) => {
      const _newList = selectedState.filter((p) => p !== id)
      dispatch(setCreateSelectedState(_newList))
    },
    [dispatch, selectedState]
  )

  const handleClickCreate = () => {
    if (channelId && roomName && communityId) {
      const newChatRoom = {
        chatRoomName: roomName,
        chatRoomBio: roomBio,
        communityId,
        channelId: channelId,
        type: 'default', //// what is kind of types?
        userIds: selectedState.map((s) => ({ userId: s, isPinned: false })),
      }
      dispatch(createChatRoom(newChatRoom))
        .unwrap()
        .then(() => {
          navigate(Routes.CHATROOMS)
        })
    }
  }

  useEffect(() => {
    if (!selectedState.length) {
      navigate(AbsoluteRoutes.GO_BACK)
    }
  }, [navigate, selectedState.length])

  return (
    <>
      <TopNavigationLayout>
        <TopNavigatorLink to={AbsoluteRoutes.GO_BACK}>Back</TopNavigatorLink>
        <Text className="top_navigation_title" fontWeight="w700">
          Chatroom info
        </Text>
        <TopNavigatorLink
          to={'#'}
          onClick={handleClickCreate}
          disabled={!roomName}
        >
          Create
        </TopNavigatorLink>
      </TopNavigationLayout>
      <PageLayout className="chat_room_create_page_container">
        <SettingsChatRoom
          isCreate
          nameValue={roomName}
          bioValue={roomBio}
          changeNameValue={setRoomName}
          changeBioValue={setRoomBio}
        />
        <ParticipantsChatRoom
          selectedState={selectedState}
          changeSelectedState={setSelectedState}
          isOwner={true}
          withSelectAction={false}
          data={participansList}
          deleteParticipanst={handleDeletePartisipan}
        />
      </PageLayout>
    </>
  )
}

export default ChatRoomCreatePageSettings
