import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ProfileResponce } from "api/models/profile";
import { createTypedSelector } from "store/utils";
import { getProfile } from "../async-actions/profile";

interface InitialState {
  profileData: ProfileResponce;
  isLoading: boolean;
  communityId: number | null;
}

const initialState: InitialState = {
  profileData: {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    profilePhoto: "",
    profileId: "",
    countryCode: "",
    phoneCode: "",
    phoneExt: null,
    phoneNo: null,
    userId: null,
  },
  isLoading: true,
  communityId: 2, // was 22
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setCommunityId: (state, action: PayloadAction<number>) => {
      state.communityId = action.payload ?? initialState.communityId;
    },
  },
  extraReducers: {
    [getProfile.fulfilled.type]: (
      state,
      actions: PayloadAction<ProfileResponce>
    ) => {
      state.isLoading = false;
      state.profileData = actions.payload;
    },
    [getProfile.rejected.type]: (state) => {
      state.isLoading = false;
    },
  },
});

export const getIsLoadingProfileSelector = createTypedSelector(
  (state) => state.profile.isLoading
);

export const getProfileIdSelector = createTypedSelector(
  (state) => state.profile.profileData.profileId
);

export const getProfileUserIdSelector = createTypedSelector(
  (state) => state.profile.profileData.userId
);

export const getCommunityIdSelector = createTypedSelector(
  (state) => state.profile.communityId
);

export const getProfileUserNameSelector = createTypedSelector(
  (state) => state.profile.profileData.userName
);

export const { setCommunityId } = profileSlice.actions;

export default profileSlice.reducer;
