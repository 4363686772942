import React from "react";

const FeedButtonIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4166 2L15.75 7.33333L9.08329 2M8.81663 26H22.6833C24.9235 26 26.0436 26 26.8993 25.564C27.6519 25.1805 28.2638 24.5686 28.6473 23.816C29.0833 22.9603 29.0833 21.8402 29.0833 19.6V13.7333C29.0833 11.4931 29.0833 10.373 28.6473 9.51737C28.2638 8.76472 27.6519 8.1528 26.8993 7.76931C26.0436 7.33333 24.9235 7.33333 22.6833 7.33333H8.81663C6.57642 7.33333 5.45631 7.33333 4.60066 7.76931C3.84802 8.1528 3.23609 8.76472 2.8526 9.51737C2.41663 10.373 2.41663 11.4931 2.41663 13.7333V19.6C2.41663 21.8402 2.41663 22.9603 2.8526 23.816C3.23609 24.5686 3.84802 25.1805 4.60066 25.564C5.45631 26 6.57642 26 8.81663 26Z"
        stroke="currentColor"
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FeedButtonIcon;
