import axios, { AxiosError, AxiosInstance } from "axios";
import { v4 as uuidv4 } from "uuid";

import { BASE_AUTH, BASE_URL, IS_LOCAL } from "./config";

import storage, { StorageService } from "services/storage";
import communityApi, { CommunityApi } from "./routes/community";
import contentApi, { ContentApi } from "./routes/content";
import chatApi, { ChatApi } from "./routes/chat";
import profileApi, { ProfileApi } from "./routes/profile";
import searchApi, { SearchApi } from "./routes/search";
import analyticsApi, { Analytics } from "./routes/analytics";

type Token = string | null;

export class ClientApi {
  private _token: Token = null;

  public readonly community: CommunityApi;
  public readonly content: ContentApi;
  public readonly chat: ChatApi;
  public readonly profile: ProfileApi;
  public readonly search: SearchApi;
  public readonly analytics: Analytics;

  constructor(
    private apiProvider: AxiosInstance,
    private storage: StorageService,
    private readonly _sessionId: string
  ) {
    this.apiProvider.interceptors.request.use((config) => {
      config.headers = {
        ...config.headers,
        "Basic-Auth": BASE_AUTH,
      };

      if (this._token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${this._token}`,
        };
      }

      return config;
    });

    this.apiProvider.interceptors.response.use(
      (res) => res,
      async (error: AxiosError) => {
        if (error.response?.status === 401) {
          await this.updateToken();
        }
        throw error;
      }
    );

    this.community = communityApi(this.apiProvider);
    this.content = contentApi(this.apiProvider);
    this.search = searchApi(this.apiProvider);
    this.chat = chatApi(this.apiProvider);
    this.profile = profileApi(this.apiProvider);
    this.analytics = analyticsApi(this.apiProvider);
  }

  get sessionId() {
    return this._sessionId;
  }

  set token(newToken: Token) {
    this._token = newToken;
  }

  /**
   * @refactoring
   */
  private async updateToken() {
    if (IS_LOCAL) {
      const { data } = await this.profile.login();
      this.token = data.data.accessToken;
      this.storage.setItem("accessToken", data.data.accessToken);
    }
  }
}

const config = {
  baseURL: `${BASE_URL}`,
  timeout: 15000,
};

const clientApi = new ClientApi(axios.create(config), storage, uuidv4());

export default clientApi;
