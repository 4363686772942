import { useState, memo } from "react";
import IconButton from "@mui/material/IconButton";

import MutedIcon from "components/common/svg/MutedIcon";
import NoPostsIcon from "components/common/svg/NoPostsIcon";
import UnmutedIcon from "components/common/svg/UnmutedIcon";
import AncholForPaggination from "components/common/AncholForPaggination";

import ContentItem from "./ContentItem";
import EmptyContent from "./EmptyContent";

import { useAppShallowSelector } from "hooks/redux";
import { getActiveChannelSelector } from "store/reducers/channels";
import { useScrollPagination } from "hooks/useScrollPagination";
import { getChannelContentsSelector } from "store/reducers/content";

import { useChannelContent } from "../useChannelContent";

const LIMIT_OF_LOAD_PAGE_CONTENT = 10;

const ContentSection = () => {
  const [isMuted, setIsMuted] = useState(true);

  const activeChanel = useAppShallowSelector(getActiveChannelSelector);
  const channelContent = useAppShallowSelector(getChannelContentsSelector);

  const loadMoreContent = useChannelContent();
  const loadRef = useScrollPagination(
    loadMoreContent,
    channelContent.length >= LIMIT_OF_LOAD_PAGE_CONTENT
  );

  const handleClickMuted = () => {
    setIsMuted((m) => !m);
  };

  const loadMoreAnchor = channelContent.length >=
    LIMIT_OF_LOAD_PAGE_CONTENT && (
    <AncholForPaggination ref={loadRef} className="load_more_anchor" />
  );

  return (
    <>
      {channelContent?.length ? (
        <div className="channel_content_section">
          {channelContent?.map((item) => {
            return (
              <ContentItem
                key={item.id}
                contentId={item.id}
                contentType={activeChanel?.channelType}
                contentSrc={item.sourceURL}
                previewImage={item.previewImage}
                isMuted={isMuted}
              />
            );
          })}
          {loadMoreAnchor}
        </div>
      ) : (
        <EmptyContent />
      )}
      <IconButton className="muted_button" onClick={handleClickMuted}>
        {isMuted ? <UnmutedIcon /> : <MutedIcon />}
      </IconButton>
    </>
  );
};

export default memo(ContentSection);
