import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Link } from "react-router-dom";

import { Routes } from "routes";

import Text from "components/common/Text";

const FlatActionButton = () => {
  return (
    <div className="flat_action_button_container">
      <Link className="flat_action_button" to={Routes.CHATROOMS_CREATE}>
        <AddRoundedIcon className="button_icon" />
        <Text className="flat_action_button_label">Chat room</Text>
      </Link>
    </div>
  );
};

export default FlatActionButton;
