import React from 'react'

import Text from 'components/common/Text'

import { Chatroom } from '../ChatRoomsPage'
import ChatRoomItem from './ChatRoomItem'

interface Props {
  title: string
  dataList: Chatroom[]
}

const ChatroomsListSections: React.FC<Props> = ({ title = '', dataList }) => {
  return (
    <div className="chatrooms_section">
      <span className="chatrooms_subtitle_container">
        <Text className="chatrooms_subtitle" fontWeight="w700">
          {title}
        </Text>
      </span>
      {dataList.map((item) => {
        return <ChatRoomItem key={item.id} {...item} />
      })}
    </div>
  )
}

export default ChatroomsListSections
