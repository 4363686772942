import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import ChannelsPage from "pages/ChannelsPage";
import ChannelsModal from "pages/ChannelsListPage";
import SearchPage from "pages/SearchPage";
import ChatRoomsPage from "pages/ChatRoomsPage/ChatRoomsPage";
import ChatRoomPage from "pages/ChatRoomPage";
import ChatRoomSettingsPage from "pages/ChatRoomSettingsPage";
import ChatRoomCreatePageAddParticipants from "pages/ChatRoomCreatePageAddParticipants";
import ChatRoomCreatePageSettings from "pages/ChatRoomCreatePageSettings";
import MainLayout from "components/MainLayout";

import ChatRoomDetail from "pages/ChatRoomDetail";
import ChatRoomCreatePage from "pages/ChatRoomCreatePage";

import ChatsPagesContainer from "pages/ChatsPagesContainer";

export enum Routes {
  BASE = "/",
  CHANELS = "/channels",
  CHANNELMODAL = "/channelmodal",
  SEARCH = "/search",
  CHATROOMS = "/chatrooms",
  CHATROOM = "/chatrooms/:roomId",
  CHATROOM_SETTINGS = "/chatrooms/:roomId/settings",
  CHATROOMS_CREATE = "/chatrooms/create",
  CHATROOMS_CREATE_NEXT = "/chatrooms/create/next",
}

export enum AbsoluteRoutes {
  GO_BACK = "..",
  CHANELS = "channels",
  CHANNELMODAL = "channelmodal",
  SEARCH = "search",
  CHATROOMS = "chatrooms",
  CHATROOM_SETTINGS = "settings",
  CHATROOMS_CREATE = "create",
  CHATROOMS_CREATE_NEXT = "next",
}

const router = createBrowserRouter([
  {
    path: Routes.BASE,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.CHANELS} replace={true} />,
      },
      {
        path: Routes.CHANELS,
        element: <ChannelsPage />,
      },
      {
        path: Routes.CHANNELMODAL,
        element: <ChannelsModal />,
      },
      {
        path: Routes.SEARCH,
        element: <SearchPage />,
      },
      {
        path: Routes.CHATROOMS,
        element: <ChatsPagesContainer />,
        children: [
          {
            path: Routes.CHATROOMS,
            element: <ChatRoomsPage />,
          },
          {
            path: Routes.CHATROOM,
            element: <ChatRoomDetail />,
            children: [
              {
                path: Routes.CHATROOM,
                element: <ChatRoomPage />,
              },
              {
                path: Routes.CHATROOM_SETTINGS,
                element: <ChatRoomSettingsPage />,
              },
            ],
          },
          {
            path: Routes.CHATROOMS_CREATE,
            element: <ChatRoomCreatePage />,
            children: [
              {
                path: Routes.CHATROOMS_CREATE,
                element: <ChatRoomCreatePageAddParticipants />,
              },
              {
                path: Routes.CHATROOMS_CREATE_NEXT,
                element: <ChatRoomCreatePageSettings />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const BaseRoute = () => {
  return <RouterProvider router={router} />;
};

export default BaseRoute;
