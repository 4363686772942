import { useRef, useEffect, useMemo, useCallback } from 'react'
import debounce from 'lodash/debounce'

import { useAppShallowSelector, useAppDispatch } from 'hooks/redux'
import { getActiveChannelUUID } from 'store/reducers/channels'
import {
  getSearchContents,
  getSearchContentsPaggination,
} from 'store/async-actions/content'

export const useSearchContents = (searchValue: string): (() => void) => {
  const pageRef = useRef<number>(0)
  const dispatch = useAppDispatch()

  const activeChanelUUID = useAppShallowSelector(getActiveChannelUUID)

  const loadSearchContents = useCallback(() => {
    if (activeChanelUUID) {
      pageRef.current = 0
      dispatch(
        getSearchContents({
          channelId: activeChanelUUID,
          pageNumber: pageRef.current,
          searchWords: searchValue,
        })
      )
    }
  }, [activeChanelUUID, dispatch, searchValue])

  const debouncedResults = useMemo(() => {
    return debounce(loadSearchContents, 400)
  }, [loadSearchContents])

  const loadMoreContent = useCallback(() => {
    if (activeChanelUUID) {
      dispatch(
        getSearchContentsPaggination({
          channelId: activeChanelUUID,
          pageNumber: pageRef.current + 1,
          searchWords: searchValue,
        })
      )
        .unwrap()
        .then((result) => {
          if (result.length) {
            pageRef.current = pageRef.current + 1
          }
        })
    }
  }, [activeChanelUUID, dispatch, searchValue])

  useEffect(() => {
    debouncedResults()
    return () => debouncedResults.cancel()
  }, [debouncedResults, loadSearchContents])

  return loadMoreContent
}
