import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import {
  GetVideoCommentViewerMessagesRequestBody,
  GetVideoMessagesByOwnerRequestData,
  GetVideoRoomsByOwnerRequestData,
  SendMessagesByOwnerRequestData,
  SendViewerTextMessageRequestBody,
} from "api/models/chat";

import {
  ChannelContentsDataRequest,
  SearchDataRequest,
} from "api/models/content";
import { AxiosProgressEvent } from "axios";

export const getChannelContents = createAsyncThunk(
  "content/getChannelContents",
  async (body: ChannelContentsDataRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.content.getChannelContents(body);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getSearchContents = createAsyncThunk(
  "content/getSearchContents",
  async (body: SearchDataRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.search.getSearchContents(body);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getSearchContentsPaggination = createAsyncThunk(
  "content/getSearchContentsPaggination",
  async (body: SearchDataRequest, { rejectWithValue }) => {
    try {
      const { data } = await api.search.getSearchContents(body);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getVideoCommentViewerMessages = createAsyncThunk(
  "content/getVideoCommentViewerMessages",
  async (
    payload: GetVideoCommentViewerMessagesRequestBody,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.chat.getVideoCommentViewerMessages(payload);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const sendViewerTextMessages = createAsyncThunk(
  "content/sendViewerTextMessages",
  async (payload: SendViewerTextMessageRequestBody, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendViewerTextMessages(payload);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getVideoRoomsByOwner = createAsyncThunk(
  "content/getVideoRoomsByOwner",
  async (payload: GetVideoRoomsByOwnerRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getVideoRoomsByOwner(payload);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const getVideoMessagesByOwner = createAsyncThunk(
  "content/getVideoMessagesByOwner",
  async (payload: GetVideoMessagesByOwnerRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getVideoMessagesByOwner(payload);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const sendMessagesByOwner = createAsyncThunk(
  "content/sendMessagesByOwner",
  async (payload: SendMessagesByOwnerRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendMessagesByOwner(payload);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message);
    }
  }
);

export const sendCommentAttachmentFileByOwner = createAsyncThunk(
  "content/sendCommentAttachmentFileByOwner",
  async (
    {
      body,
      cb,
      abortSignal,
    }: {
      body: FormData;
      cb: HandlePropgressCb<AxiosProgressEvent>;
      abortSignal: AbortSignal;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.chat.sendCommentAttachmentFileByOwner(
        body,
        cb,
        abortSignal
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message || "Something went wrong");
    }
  }
);

export const sendCommentVoiceByOwner = createAsyncThunk(
  "content/sendCommentVoiceByOwner",
  async (body: FormData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendCommentVoiceByOwner(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const sendCommentAttachmentFileByViewer = createAsyncThunk(
  "content/sendCommentAttachmentFileByViewer",
  async (
    {
      body,
      cb,
      abortSignal,
    }: {
      body: FormData;
      cb: HandlePropgressCb<AxiosProgressEvent>;
      abortSignal: AbortSignal;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.chat.sendCommentAttachmentFileByViewer(
        body,
        cb,
        abortSignal
      );
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message || "Something went wrong");
    }
  }
);

export const sendCommentVoiceByViewer = createAsyncThunk(
  "content/sendCommentVoiceByViewer",
  async (body: FormData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendCommentVoiceByViewer(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);
