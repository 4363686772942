import { AxiosInstance, AxiosPromise } from "axios";

import BaseApi from "api/base";

import {
  Channels,
  ChannelTabs,
  GetChannelRequestData,
  GetChannelTabsRequestData,
  ChannelUser,
} from "../models/community";
import { BaseResponse } from "../models/base";
import { UsersListOfChatRoomDataRequest } from "api/models/chat";
export class CommunityApi extends BaseApi {
  getOrganizationsChannelList(
    data: GetChannelRequestData
  ): AxiosPromise<BaseResponse<Channels>> {
    return this.request({
      url: `${this.url}/Profile/role/channel/list`,
      method: "POST",
      data,
    });
  }

  getChannelTabs(
    data: GetChannelTabsRequestData
  ): AxiosPromise<BaseResponse<ChannelTabs>> {
    return this.request({
      url: `${this.url}/Channel/tabs`,
      method: "POST",
      data,
    });
  }

  getChannelUsersList(
    data: UsersListOfChatRoomDataRequest
  ): AxiosPromise<BaseResponse<ChannelUser[]>> {
    return this.request({
      url: `${this.url}/admin/channel/users`,
      method: "POST",
      data,
    });
  }


}

export default function communityApi(request: AxiosInstance) {
  return new CommunityApi({
    url: "/community",
    request,
  });
}
