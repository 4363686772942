import { useAppShallowSelector } from "hooks/redux";
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { getActiveVideoSelector } from "store/reducers/content";

import ChannelPage from "./ChannelsPage";

export enum TabValues {
  MORE_INFO = "moreInfo",
  FEED = "feed",
  COMMENTS = "comments",
}

interface ChannelContextValues {
  searchContentId: string | null;
  clearSearchId: () => void;
}

type ActiveTabContextValue = {
  activeTopTab: TabValues;
  setActiveTopTab: (v: TabValues) => void;
};

const ChannelContext = createContext<ChannelContextValues>(
  {} as ChannelContextValues
);

const ActiveTabContext = createContext<ActiveTabContextValue>(
  {} as ActiveTabContextValue
);

const ActiveVideoContext = createContext<
  ReturnType<typeof getActiveVideoSelector>
>({} as ReturnType<typeof getActiveVideoSelector>);

const ChannelPageContainer = () => {
  const { state } = useLocation() as { state: { contentId: string } | null };
  const [searchContentId, setSearchContentId] = useState<string>(() =>
    state ? state.contentId : ""
  );
  const [activeTopTab, setActiveTopTab] = useState<TabValues>(TabValues.FEED);

  const activeVideo = useAppShallowSelector(getActiveVideoSelector);

  const clearSearchId = useCallback(() => {
    setSearchContentId("");
  }, []);

  const activeTopTabValues = useMemo(
    () => ({
      activeTopTab,
      setActiveTopTab,
    }),
    [activeTopTab]
  );

  const searchContextValues = useMemo(
    () => ({
      searchContentId,
      clearSearchId,
    }),
    [clearSearchId, searchContentId]
  );

  return (
    <ActiveVideoContext.Provider value={activeVideo}>
      <ActiveTabContext.Provider value={activeTopTabValues}>
        <ChannelContext.Provider value={searchContextValues}>
          <ChannelPage />
        </ChannelContext.Provider>
      </ActiveTabContext.Provider>
    </ActiveVideoContext.Provider>
  );
};

export const useChannelContext = () => useContext(ChannelContext);
export const useActiveTabContext = () => useContext(ActiveTabContext);
export const useActiveVideoContext = () => useContext(ActiveVideoContext);

export default ChannelPageContainer;
