import React, { memo, useEffect, useRef, useState, useCallback } from "react";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import PlayIcon from "components/common/svg/PlayIcon";
import VideoEpisode from "components/VideoEpisode";
import AudioEpisode from "components/AudioEpisode";
import {
  useActiveTabContext,
  useChannelContext,
  TabValues,
} from "../ChannelPageContext";

import {
  getActiveContentIdSelector,
  setActiveVideoId,
} from "store/reducers/content";
import clientApi from "api";

import {
  getCommunityIdSelector,
  getProfileIdSelector,
} from "store/reducers/profile";
import {
  getActiveChannelIdentifierSelector,
  getActiveChannelUUID,
} from "store/reducers/channels";

interface ContentItemProps {
  contentType: ContentType | undefined;
  previewImage: string; //// poster, previewSrc
  contentId: string; /// videoId, id
  contentSrc: string; /// src, audioSrc
  isMuted: boolean;
}

const ContentItem: React.FC<ContentItemProps> = ({
  contentType,
  previewImage,
  contentId,
  contentSrc,
  isMuted,
}) => {
  const contentRef = useRef<HTMLVideoElement & HTMLAudioElement>(null);

  const dispatch = useAppDispatch();
  const { searchContentId, clearSearchId } = useChannelContext();
  const { activeTopTab } = useActiveTabContext();

  const activeContentId = useAppSelector(getActiveContentIdSelector);
  const userId = useAppSelector(getProfileIdSelector);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  const handleClickCapture = useCallback(async () => {
    if (contentRef.current) {
      if (contentRef.current.paused) {
        await contentRef.current.play();
      } else {
        contentRef.current.pause();
      }
    }
  }, []);

  const handleCompletedVideo = useCallback(() => {
    let duration = 0;
    let currentDuration = 0;
    if (contentRef.current?.duration !== undefined) {
      duration = contentRef.current?.duration;
      currentDuration = contentRef.current?.currentTime;
    }
    handleClickCapture();
    clientApi.analytics.completedVideo({
      video: contentSrc,
      userId,
      durationSeconds: duration,
      currentSeconds: currentDuration,
    });
  }, []);

  const handleClickPlay = useCallback(() => {
    let duration = 0;
    let currentDuration = 0;
    if (contentRef.current?.duration !== undefined) {
      duration = contentRef.current?.duration;
      currentDuration = contentRef.current?.currentTime;
    }
    setIsPlaying(true);
    clientApi.analytics.startVideo({
      video: contentSrc,
      userId,
      durationSeconds: duration,
      currentSeconds: currentDuration,
    });
  }, []);

  // video: string,
  // userId: string,
  // durationSeconds: number,
  // currentSeconds: number,
  // channelId: number,
  // communityId: number,
  // ip: string

  const handleClickPause = useCallback(() => {
    let duration = 0;
    let currentDuration = 0;
    if (contentRef.current?.duration !== undefined) {
      duration = contentRef.current?.duration;
      currentDuration = contentRef.current?.currentTime;
    }
    setIsPlaying(false);
    clientApi.analytics.stopVideo({
      video: contentSrc,
      userId,
      durationSeconds: duration,
      currentSeconds: currentDuration,
    });
  }, []);

  useEffect(() => {
    if (
      searchContentId &&
      searchContentId === contentId &&
      contentRef.current
    ) {
      contentRef.current.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
    clearSearchId();
  }, [searchContentId, contentId, clearSearchId]);

  useEffect(() => {
    const proccesContent = async () => {
      if (contentRef.current) {
        if ([TabValues.COMMENTS, TabValues.MORE_INFO].includes(activeTopTab)) {
          contentRef.current.pause();
        }
        if (activeTopTab === TabValues.FEED && contentId === activeContentId) {
          await contentRef.current.play();
        }
      }
    };

    const TIMER = searchContentId ? 500 : 0;

    const timeout = setTimeout(() => {
      proccesContent();
    }, TIMER);

    return () => clearTimeout(timeout);
  }, [activeContentId, activeTopTab, contentId, searchContentId]);

  useEffect(() => {
    if (contentRef.current) {
      const videoObserver = new IntersectionObserver(
        ([entry]: IntersectionObserverEntry[]) => {
          if (!entry.isIntersecting || entry.intersectionRatio < 0.15) {
            contentRef?.current?.pause();
          } else if (entry.isIntersecting || entry.intersectionRatio > 0.15) {
            dispatch(setActiveVideoId(contentId));
            //videoRef?.current?.play()
          }
        },
        {
          threshold: [0.3],
          //root: document,
        }
      );

      videoObserver.observe(contentRef.current);

      return () => {
        if (contentRef.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          videoObserver.unobserve(contentRef.current);
        }
      };
    }
  }, [contentId, dispatch]);

  if (!contentType) return null;

  return (
    <div className="content_episode_container">
      {contentType === "VIDEO" && (
        <VideoEpisode
          onEnded={handleCompletedVideo}
          loop={false}
          ref={contentRef}
          playsInline
          className="content_episode"
          src={contentSrc}
          poster={previewImage}
          muted={isMuted}
          onPlay={handleClickPlay}
          onPause={handleClickPause}
          onClickCapture={handleClickCapture}
        />
      )}
      {contentType === "AUDIO" && (
        <AudioEpisode
          ref={contentRef}
          loop={false}
          playsInline
          className="content_episode"
          src={contentSrc}
          muted={isMuted}
          onPlay={handleClickPlay}
          onPause={handleClickPause}
          onClickCapture={handleClickCapture}
          onEnded={handleCompletedVideo}
        />
      )}
      {!isPlaying && (
        <PlayIcon
          className="content_episode_play_icon"
          onClick={handleClickCapture}
        />
      )}
    </div>
  );
};

export default memo(ContentItem);
