import React from 'react'

interface Props {
  className?: string
}

const PageLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className = '',
}) => {
  return <div className={`page_container ${className}`}>{children}</div>
}

export default PageLayout
