import React from "react";

const ChatButtonIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14737 13.4555C7.06735 12.9398 7.02584 12.4115 7.02584 11.8736C7.02584 6.18566 11.6671 1.57471 17.3924 1.57471C23.1178 1.57471 27.7591 6.18566 27.7591 11.8736C27.7591 13.1584 27.5222 14.3883 27.0895 15.5226C26.9997 15.7581 26.9547 15.8759 26.9343 15.9679C26.9141 16.059 26.9063 16.1231 26.9041 16.2164C26.9019 16.3106 26.9147 16.4143 26.9402 16.6217L27.4585 20.8317C27.5146 21.2874 27.5426 21.5153 27.4668 21.6809C27.4004 21.8261 27.2824 21.9414 27.1358 22.0044C26.9684 22.0764 26.7413 22.0431 26.287 21.9765L22.1864 21.3754C21.9723 21.344 21.8652 21.3283 21.7677 21.3289C21.6713 21.3294 21.6045 21.3366 21.5101 21.3564C21.4147 21.3765 21.2928 21.4221 21.049 21.5134C19.9119 21.9394 18.6796 22.1724 17.3924 22.1724C16.8541 22.1724 16.3253 22.1316 15.8091 22.053M9.12631 27.3218C12.9432 27.3218 16.0374 24.1518 16.0374 20.2414C16.0374 16.3309 12.9432 13.1609 9.12631 13.1609C5.30943 13.1609 2.21524 16.3309 2.21524 20.2414C2.21524 21.0274 2.34027 21.7836 2.57106 22.49C2.66861 22.7887 2.71739 22.938 2.7334 23.04C2.75012 23.1465 2.75304 23.2063 2.74682 23.3139C2.74086 23.417 2.71507 23.5335 2.66348 23.7666L1.87646 27.3218L5.73184 26.7953C5.94228 26.7666 6.0475 26.7522 6.13938 26.7528C6.23612 26.7535 6.28747 26.7587 6.38235 26.7776C6.47246 26.7956 6.60642 26.8429 6.87433 26.9374C7.58022 27.1866 8.33784 27.3218 9.12631 27.3218Z"
        stroke="currentColor"
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatButtonIcon;
