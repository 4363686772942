import { memo } from "react";
import { NavLink } from "react-router-dom";
import { AbsoluteRoutes } from "routes";

import ChannelButtonIcon from "components/common/svg/ChannelButtonIcon";
import ChatButtonIcon from "components/common/svg/ChatButtonIcon";
import FeedButtonIcon from "components/common/svg/FeedButtonIcon";
import SearchButtonIcon from "components/common/svg/SearchButtonIcon";

const getMenuItemClassName = ({ isActive }: { isActive: boolean }): string =>
  isActive ? "menu_item active-item" : "menu_item";

const BottomNavMenu = () => {
  return (
    <div className="menu_container">
      <NavLink
        to={AbsoluteRoutes.CHANNELMODAL}
        className={getMenuItemClassName}
      >
        <ChannelButtonIcon />
      </NavLink>
      <NavLink to={AbsoluteRoutes.CHANELS} className={getMenuItemClassName}>
        <FeedButtonIcon />
      </NavLink>
      <NavLink to={AbsoluteRoutes.SEARCH} className={getMenuItemClassName}>
        <SearchButtonIcon />
      </NavLink>
      <NavLink to={AbsoluteRoutes.CHATROOMS} className={getMenuItemClassName}>
        <ChatButtonIcon />
      </NavLink>
    </div>
  );
};

export default memo(BottomNavMenu);
