import { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import clientApi from "api";
import { useAppSelector } from "./redux";
import { getOrganizationUuidActiveChanelSelector } from "store/reducers/channels";

interface FetchParams {
  ctaName: string;
  pageName: string;
  organizationUuid?: string;
}

const fetch = async (params: FetchParams) => {
  await clientApi.analytics.fetchCTALogs({
    sessionId: clientApi.sessionId,
    appName: "plugin",
    ...params,
  });
};

export const useAnalytics = () => {
  const location = useLocation();
  const organizationUuid = useAppSelector(
    getOrganizationUuidActiveChanelSelector
  );

  const handleAnalyticsEvent = useCallback(
    async (e: any) => {
      if (organizationUuid) {
        await fetch({ organizationUuid: organizationUuid, ...e?.detail });
      }
    },
    [organizationUuid]
  );

  useEffect(() => {
    window.addEventListener("analyticsEvent", handleAnalyticsEvent);
    return () => {
      window.removeEventListener("analyticsEvent", handleAnalyticsEvent);
    };
  }, [handleAnalyticsEvent]);

  useEffect(() => {
    if (organizationUuid) {
      window.dispatchEvent(
        new CustomEvent<FetchParams>("analyticsEvent", {
          detail: {
            ctaName: "changePage",
            pageName: location.pathname.slice(1),
            organizationUuid: organizationUuid,
          },
        })
      );
    }
  }, [location.pathname, organizationUuid]);
};
