import { useCallback } from "react";
import { useParams, Params } from "react-router-dom";
import { AxiosProgressEvent } from "axios";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import PageLayout from "components/PageLayout";
import NavigationSections from "./components/NavigationSections";
import Chat from "components/Chat";
import {
  getChatRoomNameSelector,
  getOrganizationUuidSelector,
} from "store/reducers/chatrooms";
import {
  getProfileUserIdSelector,
  getProfileUserNameSelector,
} from "store/reducers/profile";
import {
  sendChatMessage,
  sendChatAttachmentMessage,
  sendVoiceMessage,
} from "store/async-actions/chat";

import { useChatMessages } from "./useChatMessages";
import { useMentioningUsersList } from "./useMentioningUsersList";
import { useSearchMe } from "./useSearchMe";
import { getIsFetchingSendMessageSelector } from "store/reducers/chat";

type AvailableFormDataKeys =
  | "chatRoomId"
  | "message"
  | "userName"
  | "organizationUuid"
  | "file"
  | "type"
  | "mentionUsersIds";
interface TypedFormData extends FormData {
  append(
    name: AvailableFormDataKeys,
    value: string | Blob,
    fileName?: string
  ): void;
}

const ChatRoom = () => {
  const dispatch = useAppDispatch();
  const { roomId = "" } = useParams<Params<"roomId">>();

  const roomName = useAppSelector(getChatRoomNameSelector);
  const userId = useAppSelector(getProfileUserIdSelector);
  const userName = useAppSelector(getProfileUserNameSelector);
  const organizationUuid = useAppSelector(getOrganizationUuidSelector);
  const isLoadingAttachment = useAppSelector(getIsFetchingSendMessageSelector);

  const { loadMoreMessageList, chatList, isLoading } = useChatMessages(roomId);
  const { usersList } = useMentioningUsersList(roomId);
  const {
    isShowSearchControls,
    closeSearchControls,
    chatListWithMention,
    detectSearchSymbol,
    chatListWithMentionLength,
  } = useSearchMe(roomId, userId);

  const handleSendMessage = useCallback(
    async ({
      content = "",
      file,
      type = "attachment",
      cb,
      abortSignal,
      mentionUsersIds = [],
    }: SendMessageParams<AxiosProgressEvent, AbortSignal>) => {
      if (roomId && userId) {
        if (file) {
          const formData = new FormData() as TypedFormData;
          formData.append("chatRoomId", roomId);
          formData.append("message", content);
          formData.append("userName", userName);
          formData.append("organizationUuid", organizationUuid);
          formData.append("file", file as File);
          if (type === "voice") {
            formData.append("type", "voice");
            dispatch(sendVoiceMessage(formData));
          }
          if (type === "attachment") {
            formData.append("type", "attachment");
            formData.append("mentionUsersIds", JSON.stringify(mentionUsersIds));
            await dispatch(
              sendChatAttachmentMessage({ body: formData, cb, abortSignal })
            )
              .unwrap()
              .catch((error) => {
                throw error;
              });
          }
        } else {
          dispatch(
            sendChatMessage({
              chatRoomId: roomId,
              message: content,
              type: "text",
              userId,
              userName,
              mentionUsersIds,
            })
          );
        }
      }
    },
    [dispatch, organizationUuid, roomId, userId, userName]
  );

  return (
    <>
      <NavigationSections roomName={roomName} />
      <PageLayout className="chat_room_page">
        <Chat
          handlePagginationUpdate={loadMoreMessageList}
          sendMessage={handleSendMessage}
          loading={isLoading}
          chatList={
            isShowSearchControls && chatListWithMention.length
              ? chatListWithMention
              : chatList
          }
          usersList={usersList}
          loadingAttachment={isLoadingAttachment}
          isShowSearchControls={isShowSearchControls}
          detectSearchSymbol={detectSearchSymbol}
          closeSearchControls={closeSearchControls}
          searchMentionMessagesCount={chatListWithMentionLength}
        />
      </PageLayout>
    </>
  );
};

export default ChatRoom;
