import Text from "components/common/Text";
import ChannelsItem from "./ChannelsItem";

import { useAppShallowSelector } from "hooks/redux";

import { getChannelsListSelector } from "store/reducers/channels";
import PageLayout from "components/PageLayout";

const ChannelsModal = () => {
  const channelsList = useAppShallowSelector(getChannelsListSelector);

  return (
    <PageLayout>
      <div className="channel_page_modal channels_modal_container">
        <div className="channels_modal_body">
          <Text className="modal_title" fontWeight={"w700"} as="h2">
            Channels
          </Text>
          <div style={{ marginTop: "50px" }}>
            {channelsList.length
              ? channelsList?.map(
                  (
                    {
                      channelLogo,
                      channelName,
                      description,
                      channelIdentifier,
                    },
                    index: number
                  ) => (
                    <ChannelsItem
                      key={index}
                      channelIdentifier={channelIdentifier}
                      channelLogo={channelLogo}
                      title={channelName}
                      description={description}
                    />
                  )
                )
              : null}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ChannelsModal;
