import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ChannelContentsDataResponse } from 'api/models/content'

import Avatar from 'components/common/Avatar'
import { Routes } from 'routes'
import { useAppDispatch } from 'hooks/redux'
import { setFoundContent } from 'store/reducers/content'

const ContentItem: React.FC<ChannelContentsDataResponse> = (props) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const navigateToFeed = () => {
    dispatch(setFoundContent(props))
    navigate(Routes.CHANELS, { state: { contentId: props.id } })
  }

  return (
    <div className="video_item" onClick={navigateToFeed}>
      <Avatar
        src={props?.previewImage}
        alt={`${props.name} video preview`}
        variant="rounded"
        className="video_item_preview"
      />
    </div>
  )
}

export default ContentItem
