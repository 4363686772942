import { useRef, useEffect, useCallback } from 'react'

import { useAppShallowSelector, useAppDispatch } from 'hooks/redux'
import { getActiveChannelSelector } from 'store/reducers/channels'
import { getChannelContents } from 'store/async-actions/content'

export const useChannelContent = (): (() => void) => {
  const channelContentPage = useRef<number>(0)
  const dispatch = useAppDispatch()

  const activeChanel = useAppShallowSelector(getActiveChannelSelector)

  const loadMoreContent = useCallback(() => {
    if (activeChanel?.organizationUUID && activeChanel?.channelUUID) {
      dispatch(
        getChannelContents({
          organizationID: activeChanel?.organizationUUID,
          channelID: activeChanel?.channelUUID,
          page: `${channelContentPage.current + 1}`,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.length) {
            channelContentPage.current = channelContentPage.current + 1
          }
        })
    }
  }, [activeChanel?.channelUUID, activeChanel?.organizationUUID, dispatch])

  useEffect(() => {
    if (activeChanel?.organizationUUID && activeChanel?.channelUUID) {
      channelContentPage.current = 0
      dispatch(
        getChannelContents({
          organizationID: activeChanel?.organizationUUID,
          channelID: activeChanel?.channelUUID,
          page: `${channelContentPage.current}`,
        })
      )
    }
  }, [activeChanel?.channelUUID, activeChanel?.organizationUUID, dispatch])

  return loadMoreContent
}
