import BaseApi from "api/base";
import { AxiosInstance, AxiosPromise } from "axios";

import { BaseResponse } from "../models/base";
import {
  ProfileResponce,
  ValidateUserTokenResponceData,
  ValidateUserTokenRequestBody,
} from "../models/profile";

export class ProfileApi extends BaseApi {
  getProfile(): AxiosPromise<BaseResponse<ProfileResponce>> {
    return this.request({
      url: `${this.url}/Profile/Get`,
      method: "POST",
    });
  }

  //Commented this line to stop the Password Aunthetication Mechanism
  login(): AxiosPromise<BaseResponse<{ accessToken: string }>> {
    return this.request({
      url: `${this.url}/Auth/Login`,
      method: "POST",
      data: {
        userName: "vilmate@yopmail.com",
        password: "Vilmate@123",
      },
    });
  }

  validateUserUsingToken(
    data: ValidateUserTokenRequestBody
  ): AxiosPromise<BaseResponse<ValidateUserTokenResponceData>> {
    return this.request({
      url: `${this.url}/pwa/app/verify/token`,
      method: "POST",
      data,
    });
  }
}

export default function profileApi(request: AxiosInstance) {
  return new ProfileApi({
    request,
    url: "/user",
  });
}
