import { useCallback, useState } from 'react'
import { AbsoluteRoutes } from 'routes'
import PageLayout from 'components/PageLayout'
import TopNavigationLayout from 'components/TopNavigationLayout'
import TopNavigatorLink from 'components/common/TopNavigatorLink'
import Text from 'components/common/Text'

import ParticipantsChatRoom from 'components/ParticipantsChatRoom'
import { useAppDispatch, useAppShallowSelector } from 'hooks/redux'
import {
  getCreateChatRoomChannelUserSelector,
  setCreateSelectedState,
  getCreateSelectedStateSelector,
} from 'store/reducers/chatrooms'

const ChatRoomCreatePageAddParticipants = () => {
  const dispatch = useAppDispatch()

  const participansList = useAppShallowSelector(
    getCreateChatRoomChannelUserSelector
  )

  const selectedState = useAppShallowSelector(getCreateSelectedStateSelector)

  const setSelectedState = useCallback(
    (newState: UserId[]) => {
      dispatch(setCreateSelectedState(newState))
    },
    [dispatch]
  )

  return (
    <>
      <TopNavigationLayout>
        <TopNavigatorLink to={AbsoluteRoutes.GO_BACK}>Cancel</TopNavigatorLink>
        <Text className="top_navigation_title" fontWeight="w700">
          Choose people
        </Text>
        <TopNavigatorLink
          to={AbsoluteRoutes.CHATROOMS_CREATE_NEXT}
          disabled={!selectedState.length}
        >
          Next
        </TopNavigatorLink>
      </TopNavigationLayout>
      <PageLayout className="chat_room_create_page_container">
        <ParticipantsChatRoom
          data={participansList}
          isCreate={true}
          selectedState={selectedState}
          changeSelectedState={setSelectedState}
          withCount={false}
          withSelectAction={false}
        />
      </PageLayout>
    </>
  )
}

export default ChatRoomCreatePageAddParticipants
