import Text from "components/common/Text";
import { useActiveVideoContext } from "../ChannelPageContext";

const BottomButtonsSection = () => {
  const activeVideo = useActiveVideoContext();

  return (
    <div className="bottom_buttons_group">
      <div className={"content_detail_button_section"}>
        <div className="content_detail_title_block">
          <Text fontWeight="w700" className="content_detail_title">
            {activeVideo?.name || ""}
          </Text>
          <Text fontWeight="w400" className="content_detail_subtitle">
            {activeVideo?.approvedBy || ""}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default BottomButtonsSection;
