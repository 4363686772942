import React from 'react'
import { Link } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'

import { AbsoluteRoutes } from 'routes'
import Text from 'components/common/Text'

interface BackButtonProps {
  title?: string
}

const BackButton: React.FC<BackButtonProps> = ({ title = '' }) => {
  return (
    <div className="back_button_container">
      <Link className="back_button" to={AbsoluteRoutes.GO_BACK}>
        <KeyboardBackspaceRoundedIcon fontSize="inherit" />
      </Link>
      {!!title && (
        <Text
          fontWeight="w600"
          color="black"
          className="chat_room_name_title"
          as="h3"
        >
          {title}
        </Text>
      )}
    </div>
  )
}

export default BackButton
