import { AxiosInstance, AxiosProgressEvent } from "axios";

interface Config {
  request: AxiosInstance;
  url: string;
}

class BaseApi {
  protected url;
  protected request;
  constructor(config: Config) {
    this.url = config.url;
    this.request = config.request;
  }
  downloadFile(url: string, callback: (event: AxiosProgressEvent) => void) {
    return this.request({
      url,
      method: "GET",
      responseType: "blob",
      onDownloadProgress: callback,
    });
  }
}

export default BaseApi;
