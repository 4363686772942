import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import storage from "services/storage";

import { ValidateUserTokenRequestBody } from "api/models/profile";
import { setCommunityId } from "store/reducers/profile";

export const validateUser = createAsyncThunk(
  "profile/validateUser",
  async (body: ValidateUserTokenRequestBody, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.profile.validateUserUsingToken(body);
      const { authToken, communityId } = data?.data || {};
      dispatch(setCommunityId(communityId));
      storage.setItem("accessToken", authToken);
      api.token = authToken;
      return data.data;
    } catch (error) {
      return rejectWithValue("");
    }
  }
);

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (clientToken: string, { rejectWithValue, dispatch }) => {
    try {
      let _clientToken = clientToken || storage.getItem("clientToken");
      if (_clientToken) {
        storage.setItem("clientToken", _clientToken);
        await dispatch(validateUser({ clientToken: _clientToken }));
      } else {
        const accessToken = storage.getItem("accessToken");
        api.token = accessToken;
      }
      const { data } = await api.profile.getProfile();
      return data.data;
    } catch (error) {
      return rejectWithValue("Somethink went wrong");
    }
  }
);
