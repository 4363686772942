import React from "react";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";

import { useActiveVideoContext } from "../ChannelPageContext";
import Text from "components/common/Text";
import IconButton from "components/common/IconButton";
import Avatar from "components/common/Avatar";
import { ChoisenCommentRoom } from "./ChannelCommentsModal";
import { getInitialsOfPerson } from "utils";

interface TitleSectionProps {
  selectedCommentRoom?: ChoisenCommentRoom;
  onBack?: () => void;
  logoSrc?: string;
  roomName?: string;
}

const TitleModal: React.FC<TitleSectionProps> = ({
  selectedCommentRoom,
  onBack,
  logoSrc,
  roomName = "",
}) => {
  const activeVideo = useActiveVideoContext();

  const ownersTitleContent = (
    <div className="comments_owner_modal_title_container">
      <IconButton onClick={onBack} className="comment_back_button">
        <KeyboardBackspaceRoundedIcon fontSize="inherit" />
      </IconButton>
      <Avatar alt={`${roomName} room logo`} className="room_logo" src={logoSrc}>
        {getInitialsOfPerson(roomName)}
      </Avatar>
      <Text className="room_name">{roomName}</Text>
    </div>
  );

  const notOwnerContent = (
    <>
      <Text className="comments_modal_title">{activeVideo?.name ?? ""}</Text>
      <div className="tags_container">
        {activeVideo?.tags?.map((tag, i) => {
          return (
            <Text
              key={i}
              className="tag_text"
              fontWeight="w400"
            >{`#${tag}\n`}</Text>
          );
        })}
      </div>
    </>
  );

  return (
    <div className="title_section">
      {selectedCommentRoom ? ownersTitleContent : notOwnerContent}
    </div>
  );
};

export default TitleModal;
