import { memo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { TabValues, useActiveTabContext } from "../ChannelPageContext";

const TopButtonsSection = () => {
  const { activeTopTab, setActiveTopTab } = useActiveTabContext();

  const handleChange = (_e: any, value: TabValues) => {
    setActiveTopTab(value);
  };

  const whiteColorClassName = [
    TabValues.COMMENTS,
    TabValues.MORE_INFO,
  ].includes(activeTopTab)
    ? "white_style"
    : "";

  return (
    <Tabs
      value={activeTopTab}
      onChange={handleChange}
      className={`top_navigation_section_container ${whiteColorClassName}`}
      visibleScrollbar={false}
      variant="fullWidth"
      TabIndicatorProps={{
        hidden: true,
      }}
    >
      <Tab
        label="More info"
        className="top_navigation_button_item white_color"
        value={TabValues.MORE_INFO}
      />
      <Tab
        label="Feed"
        className="top_navigation_button_item"
        value={TabValues.FEED}
      />
      <Tab
        label="Comments"
        className="top_navigation_button_item  white_color"
        value={TabValues.COMMENTS}
      />
    </Tabs>
  );
};

export default memo(TopButtonsSection);
