import React from 'react'

import TopNavigationLayout from 'components/TopNavigationLayout'
import BackButton from 'components/BackButton'
import MenuButton from 'components/MenuButton'
import { AbsoluteRoutes } from 'routes'

interface NavigationSectionsProps {
  roomName: string
}

const NavigationSections: React.FC<NavigationSectionsProps> = ({
  roomName,
}) => {
  return (
    <TopNavigationLayout>
      <BackButton title={roomName} />
      <MenuButton to={AbsoluteRoutes.CHATROOM_SETTINGS} />
    </TopNavigationLayout>
  )
}

export default NavigationSections
