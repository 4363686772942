import { useEffect, memo } from "react";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { getChannelTabs } from "store/async-actions/channels";
import {
  getActiveChannelIdentifierSelector,
  getIsLoadingChannelsSelector,
} from "store/reducers/channels";
import { getIsLoadingContentSelector } from "store/reducers/content";
import PageLayout from "components/PageLayout";
import ContentDetailModal from "./components/ContentDetailModal";
import Loader from "components/common/Loader";
import ContentSection from "./components/ContentSection";
import BottomButtonsSection from "./components/BottomButtonsSection";
import TopButtonsSection from "./components/TopButtonsSection";
import ChannelCommentsModal from "./components/ChannelCommentsModal";

const ChannelsPage = () => {
  const dispatch = useAppDispatch();
  const channelIdentifier = useAppSelector(getActiveChannelIdentifierSelector);

  useEffect(() => {
    if (channelIdentifier) {
      dispatch(getChannelTabs({ channelIdentifier }));
    }
  }, [dispatch, channelIdentifier]);

  return (
    <>
      <PageLayout className="channel_page_container">
        <TopButtonsSection />
        <ContentSection />
        <BottomButtonsSection />
        <ContentDetailModal />
        <ChannelCommentsModal />
      </PageLayout>
      <PageLoader />
    </>
  );
};

const PageLoader = () => {
  const isLoadingContent = useAppSelector(getIsLoadingContentSelector);
  const isLoadingChannel = useAppSelector(getIsLoadingChannelsSelector);
  return isLoadingContent || isLoadingChannel ? <Loader /> : null;
};

export default memo(ChannelsPage);
