import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react'

import ParticipantsChatRoom from './ParticipantsChatRoom'
import DeleteConfirmatioModal from './components/DeleteConfirmatioModal'

import { Participant } from 'store/reducers/chatrooms'

interface Props {
  data: Participant[]
  selectedState: UserId[]
  changeSelectedState: (list: UserId[]) => void
  withSelectAction?: boolean
  withCount?: boolean
  isOwner?: boolean
  isCreate?: boolean
  deleteParticipanst?: (ids: UserId[]) => void
  withDeleteConfirmation?: boolean
}

interface ContextValue {
  participansList: Participant[]
  withSelectAction: boolean
  withCount: boolean
  isOpenSelectAll: boolean
  handleClickSelect: () => void
  handleClickCancel: () => void
  selectedState: UserId[]
  setSelectedState: (p: UserId[]) => void
  handleSelectAll: () => void
  isOwner: boolean
  handleDeleteParticipant: (id: UserId) => void
  handleDeleteAllParticipants: () => void
  isParticipantsEmpty: boolean
  isCreate: boolean
}

type DeleteConfirmFuncType = () => void

const ParticipantsContext = createContext<ContextValue>({} as ContextValue)

const ParticipantsChatRoomContainer: React.FC<Props> = ({
  data,
  withSelectAction = true,
  withCount = true,
  isOwner = false,
  isCreate = false,
  withDeleteConfirmation = false,
  selectedState,
  changeSelectedState,
  deleteParticipanst,
}) => {
  const deleteConfirmFunc = useRef<DeleteConfirmFuncType | null>(null)

  const [isOpenSelectAll, setIsSelectAll] = useState<boolean>(false)
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] =
    useState<boolean>(false)

  const handleClickSelect = useCallback(() => {
    setIsSelectAll(true)
  }, [])

  const handleClickCancel = useCallback(() => {
    setIsSelectAll(false)
  }, [])

  const handleSelectAll = useCallback(() => {
    changeSelectedState(data.map((i) => i.id))
  }, [data, changeSelectedState])

  const _deleteParticipants = useCallback(
    (ids: UserId[]) => {
      if (typeof deleteParticipanst === 'function') {
        deleteParticipanst(ids)
        setIsSelectAll(false)
      }
    },
    [deleteParticipanst]
  )

  const handleDeleteParticipant = useCallback(
    (id: UserId) => {
      if (withDeleteConfirmation) {
        setIsOpenConfirmationModal(true)
        deleteConfirmFunc.current = _deleteParticipants.bind(null, [id])
      } else {
        _deleteParticipants([id])
      }
    },
    [_deleteParticipants, withDeleteConfirmation]
  )

  const handleDeleteAllParticipants = useCallback(() => {
    if (withDeleteConfirmation) {
      setIsOpenConfirmationModal(true)
      deleteConfirmFunc.current = _deleteParticipants.bind(null, selectedState)
    } else {
      _deleteParticipants(selectedState)
    }
  }, [_deleteParticipants, withDeleteConfirmation, selectedState])

  const handleClickCloseConfirmModal = useCallback(() => {
    setIsOpenConfirmationModal(false)
  }, [])

  const handleClickDeleteConfirmModal = useCallback(() => {
    if (typeof deleteConfirmFunc.current === 'function') {
      deleteConfirmFunc.current()
    }
    setIsOpenConfirmationModal(false)
  }, [])

  return (
    <ParticipantsContext.Provider
      value={{
        participansList: data,
        withSelectAction,
        withCount,
        isOpenSelectAll,
        handleClickSelect,
        handleClickCancel,
        selectedState,
        setSelectedState: changeSelectedState,
        handleSelectAll,
        isOwner,
        handleDeleteParticipant,
        handleDeleteAllParticipants,
        isParticipantsEmpty: !data.length,
        isCreate,
      }}
    >
      <ParticipantsChatRoom />
      {isOpenConfirmationModal && (
        <DeleteConfirmatioModal
          onClose={handleClickCloseConfirmModal}
          onDelete={handleClickDeleteConfirmModal}
        />
      )}
    </ParticipantsContext.Provider>
  )
}

export const useParticipantsContext = () => useContext(ParticipantsContext)

export default ParticipantsChatRoomContainer
