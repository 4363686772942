import { memo, forwardRef, VideoHTMLAttributes } from 'react'

const VideoEpisode = forwardRef<
  HTMLVideoElement,
  VideoHTMLAttributes<HTMLVideoElement>
>((props, ref) => {
  return <video ref={ref} {...props} />
})

export default memo(VideoEpisode)
