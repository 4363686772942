import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import BaseRoute from "routes";

import Loader from "components/common/Loader";
import { getIsLoadingProfileSelector } from "store/reducers/profile";
import { getProfile } from "store/async-actions/profile";
import { useQuery } from "hooks/useQuery";

const SEARCH_KEY = "clientToken";

function App() {
  const dispatch = useAppDispatch();
  const isLoader = useAppSelector(getIsLoadingProfileSelector);

  const searchParams = useQuery();

  const clientToken = searchParams.get(SEARCH_KEY) as string;

  useEffect(() => {
    dispatch(getProfile(clientToken));
  }, [dispatch, clientToken]);

  if (isLoader) {
    return <Loader />;
  }

  return (
    <>
      <BaseRoute />
    </>
  );
}

export default App;
