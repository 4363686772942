import Modal from "@mui/material/Modal";

import ChannelDescription from "./ChannelDescription";
import Slide from "@mui/material/Slide";
import { TabValues, useActiveTabContext } from "../ChannelPageContext";

const ContentDetailModal = () => {
  return (
    <Modal
      open={true}
      className={`channel_page_modal`}
      closeAfterTransition
      hideBackdrop={true}
      container={document.getElementById("root")}
    >
      <Slide direction="right" in={true} mountOnEnter unmountOnExit>
        <div className={"content_detail_modal"}>
          <div className="channel_modal_body">
            <ChannelDescription />
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

const Container = () => {
  const { activeTopTab } = useActiveTabContext();
  return activeTopTab === TabValues.MORE_INFO ? <ContentDetailModal /> : null;
};

export default Container;
