import React from 'react'

import Input from 'components/common/Input'

interface SearchInputSectionProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchInputSection: React.FC<SearchInputSectionProps> = ({
  value,
  onChange,
}) => {
  return (
    <Input
      placeholder="Search"
      classNameContainer="search_input_container"
      className="search_input"
      variant="ountlined"
      value={value}
      onChange={onChange}
    />
  )
}

export default SearchInputSection
