import Avatar from "components/common/Avatar";
import Text from "components/common/Text";
import TabsPanel from "components/TabsPanel";

import { useActiveVideoContext } from "../ChannelPageContext";

const ChannelDescription = () => {
  const activeVideo = useActiveVideoContext();

  return (
    <div className="content_detail_container">
      <div className="content_detail_description">
        <Avatar
          className="content_detail_preview"
          variant="square"
          src={activeVideo?.previewImage}
          alt={`${activeVideo?.name} channel logo`}
        />
        <div className="content_detail_title_section">
          <Text className="title_text" fontWeight="w700">
            {activeVideo?.name ?? ""}
          </Text>
          <Text className="description_text" fontWeight="w400">
            {activeVideo?.description ?? ""}
          </Text>
          <div className="tags_container">
            {activeVideo?.tags?.map((tag, i) => {
              return (
                <Text
                  key={i}
                  className="tag_text"
                  fontWeight="w400"
                >{`#${tag}\n`}</Text>
              );
            })}
          </div>
        </div>
      </div>
      <TabsPanel />
    </div>
  );
};

export default ChannelDescription;
