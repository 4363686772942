import { createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api'

import {
  GetChannelRequestData,
  GetChannelTabsRequestData,
} from 'api/models/community'

export const getChannels = createAsyncThunk(
  'channels/getChannels',
  async (body: GetChannelRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.community.getOrganizationsChannelList(body)
      return data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getChannelTabs = createAsyncThunk(
  'channels/getChannelTabs',
  async (body: GetChannelTabsRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.community.getChannelTabs(body)
      return data.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
