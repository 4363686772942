import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Channels, ChannelTabs, Channel } from "api/models/community";
import { createTypedSelector } from "store/utils";
import { getChannels, getChannelTabs } from "../async-actions/channels";

interface InitialState {
  channelsList: Channels;
  isLoadingChannels: boolean;
  activeChannelIdentifier: number | null;
  channelTabs: ChannelTabs;
}

const initialState: InitialState = {
  channelsList: [],
  isLoadingChannels: false,
  activeChannelIdentifier: null,
  channelTabs: [],
};

const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    setActiveChannelId(state, action: PayloadAction<number | null>) {
      state.activeChannelIdentifier = action.payload;
    },
  },
  extraReducers: {
    [getChannels.pending.type]: (state) => {
      state.isLoadingChannels = true;
    },
    [getChannels.fulfilled.type]: (state, actions: PayloadAction<Channels>) => {
      state.isLoadingChannels = false;
      state.channelsList = actions.payload;

      const activeChannelIdentifier =
        actions.payload.find((item) => item.defaultChannel)
          ?.channelIdentifier || actions.payload?.at(0)?.channelIdentifier;

      state.activeChannelIdentifier = activeChannelIdentifier || null;
    },
    [getChannels.rejected.type]: (state) => {
      state.isLoadingChannels = false;
    },
    [getChannelTabs.pending.type]: (state) => {
      state.isLoadingChannels = true;
    },
    [getChannelTabs.fulfilled.type]: (
      state,
      actions: PayloadAction<ChannelTabs>
    ) => {
      state.isLoadingChannels = false;
      state.channelTabs = actions.payload;
    },
    [getChannelTabs.rejected.type]: (state) => {
      state.isLoadingChannels = false;
    },
  },
});

export const { setActiveChannelId } = channelsSlice.actions;

export const getIsModeratorChannelSelector = createTypedSelector((state) => {
  const activeChannelId = state.channels.activeChannelIdentifier;

  return (
    state.channels.channelsList.find(
      (channel) => channel.channelIdentifier === activeChannelId
    )?.isModerator || false
  );
});

export const getOrganizationUuidActiveChanelSelector = createTypedSelector(
  (state) => {
    const activeChannelId = state.channels.activeChannelIdentifier;

    return state.channels.channelsList.find(
      (channel) => channel.channelIdentifier === activeChannelId
    )?.organizationUUID;
  }
);

export const getActiveChannelSelector = createTypedSelector((state) => {
  const activeChannelIdentifier = state.channels.activeChannelIdentifier;
  return state.channels.channelsList.find(
    (channel) => channel.channelIdentifier === activeChannelIdentifier
  );
});

export const getIsLoadingChannelsSelector = createTypedSelector(
  (state) => state.channels.isLoadingChannels
);
export const getChannelsListSelector = createTypedSelector(
  (state) => state.channels.channelsList
);

export const getActiveChannelIdentifierSelector = createTypedSelector(
  (state) => state.channels.activeChannelIdentifier
);

export const getActiveChannelUUID = createTypedSelector((state) => {
  const activeChannel = getActiveChannelSelector(state);
  return activeChannel?.channelUUID;
});

export const TABS_TYPE = ["attribute1", "attribute2", "attribute3"];

export const getChannelTabsSelector = createTypedSelector((state) =>
  state.channels.channelTabs.filter((tab) => TABS_TYPE.includes(tab.tabType))
);

export default channelsSlice.reducer;
