import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Outlet, useParams, Params } from 'react-router-dom'

import { getChatRoomDetailById } from 'store/async-actions/chatrooms'
import { clearChatMessage } from 'store/reducers/chat'
import {
  clearChatRoomDetail,
  getIsFetchingChatRoomsSelector,
} from 'store/reducers/chatrooms'

import Loader from 'components/common/Loader'

const ChatRoomDetail = () => {
  const { roomId = '' } = useParams<Params<'roomId'>>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (roomId) {
      dispatch(getChatRoomDetailById({ chatRoomId: roomId }))
    }

    return () => {
      dispatch(clearChatRoomDetail())
      dispatch(clearChatMessage())
    }
  }, [dispatch, roomId])

  return (
    <>
      <Outlet />
      <LoaderIndicator />
    </>
  )
}

const LoaderIndicator = () => {
  const isFetching = useAppSelector(getIsFetchingChatRoomsSelector)
  return isFetching ? <Loader /> : null
}

export default ChatRoomDetail
