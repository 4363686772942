import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

import {
  GetChatMessageRequestData,
  GetChatMessagesWithUserMentionsRequestData,
  GetChatRoomDetailData,
  SendMessageRequestBody,
} from "api/models/chat";
import { AxiosProgressEvent } from "axios";

export const getChatMessages = createAsyncThunk(
  "chat/getChatMessages",
  async (body: GetChatMessageRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getMessagesOfChantRoom(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const sendChatMessage = createAsyncThunk(
  "chat/sendChatMessage",
  async (body: SendMessageRequestBody, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendMessage(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const sendChatAttachmentMessage = createAsyncThunk(
  "chat/sendChatAttachmentMessage",
  async (
    {
      body,
      cb,
      abortSignal,
    }: {
      body: FormData;
      cb?: HandlePropgressCb<AxiosProgressEvent>;
      abortSignal?: AbortSignal;
    },
    { rejectWithValue, signal }
  ) => {
    try {
      const { data } = await api.chat.sendAttachmentFile(body, cb, abortSignal);
      return data.data;
    } catch (error: any) {
      return rejectWithValue(error?.message || "Something went wrong");
    }
  }
);

export const sendVoiceMessage = createAsyncThunk(
  "chat/sendVoiceMessage",
  async (body: FormData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.sendVoice(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChatRoomUsersMentions = createAsyncThunk(
  "chat/getChatRoomUsersMentions",
  async (body: GetChatRoomDetailData, { rejectWithValue }) => {
    try {
      const { data } = await api.chat.getChannelUsersListMentions(body);
      return data.data;
    } catch (error) {
      return rejectWithValue("Something went wrong");
    }
  }
);

export const getChatMessagesWithUserMentions = createAsyncThunk(
  "chat/getChatMessagesWithUserMentions",
  async (
    body: GetChatMessagesWithUserMentionsRequestData,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.chat.getChatMessagesWithUserMentions(body);
      return data.data;
    } catch (error) {
      return rejectWithValue(
        "getChatMessagesWithUserMentions: Something went wrong"
      );
    }
  }
);
