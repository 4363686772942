import { useNavigate } from "react-router-dom";

import Avatar from "components/common/Avatar";
import Text from "components/common/Text";
import { useAppDispatch } from "hooks/redux";
import { setActiveChannelId } from "store/reducers/channels";
import { resetContent } from "store/reducers/content";
import { Routes } from "routes";

type ChannelsItemType = {
  channelLogo: string;
  title?: string;
  description?: string;
  className?: string;
  channelIdentifier: number;
};

const ChannelsItem = ({
  channelLogo,
  title = "",
  description = "",
  className = "",
  channelIdentifier,
}: ChannelsItemType) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const changeActiveChannel = async () => {
    dispatch(resetContent());
    dispatch(setActiveChannelId(channelIdentifier));
    navigate(Routes.CHANELS);
    window.dispatchEvent(
      new CustomEvent("analyticsEvent", {
        detail: {
          ctaName: `changeChannel on {channelName:${title}, channelIdentifier: ${channelIdentifier}}`,
          pageName: "channelmodal",
        },
      })
    );
  };

  return (
    <div
      className={`channel_item_container ${className}`}
      onClick={changeActiveChannel}
      tabIndex={2}
    >
      <Avatar
        className="channel_item_logo"
        src={channelLogo}
        variant="rounded"
        alt={`${title} channel logo`}
      />
      <div className="channel_item_description_block">
        <Text className="channel_item_title">{title}</Text>
        <Text className="channel_item_description">{description}</Text>
      </div>
    </div>
  );
};

export default ChannelsItem;
