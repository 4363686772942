import { configureStore } from '@reduxjs/toolkit'

import profile from './reducers/profile'
import channels from './reducers/channels'
import content from './reducers/content'
import chatrooms from './reducers/chatrooms'
import chat from './reducers/chat'

export const store = configureStore({
  reducer: {
    profile,
    channels,
    content,
    chatrooms,
    chat,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
