import React from 'react'

import Avatar from 'components/common/Avatar'
import { getParticipantByIdSelector } from 'store/reducers/chatrooms'
import { useAppShallowSelector } from 'hooks/redux'
import { getInitialsOfPerson } from 'utils'

interface TopAvatarListItemProps {
  id: UserId
}

const TopAvatarListItem: React.FC<TopAvatarListItemProps> = ({ id }) => {
  const participant = useAppShallowSelector(getParticipantByIdSelector(id))
  return (
    <Avatar
      src={participant?.profilePhoto}
      className="top_avatar_participant_item"
      alt={`${participant?.userName || participant?.userName}`}
    >
      {getInitialsOfPerson(participant?.userName)}
    </Avatar>
  )
}

export default TopAvatarListItem
