import React, { useState } from "react";

import PageLayout from "components/PageLayout";
import Text from "components/common/Text";
import AncholForPaggination from "components/common/AncholForPaggination";
import {
  getChannelSearchResultsSelector,
  getIsLoadingContentSelector,
} from "store/reducers/content";
import { getIsLoadingChannelsSelector } from "store/reducers/channels";
import { useAppSelector, useAppShallowSelector } from "hooks/redux";

import SearchInputSection from "./components/SearchInputSection";

import { useScrollPagination } from "hooks/useScrollPagination";
import { useSearchContents } from "./useSearchContents";
import Loader from "components/common/Loader";
import ContentItem from "./components/ContentItem";

const LIMIT_LOAD_PAGE_CONTENT = 9;

const SearchPage = () => {
  const [searchValue, setSearchValue] = useState<string>("");

  const channelSearchResults = useAppShallowSelector(
    getChannelSearchResultsSelector
  );

  const loadMoreVideos = useSearchContents(searchValue);
  const scrollRef = useScrollPagination<HTMLSpanElement>(
    loadMoreVideos,
    channelSearchResults.length >= LIMIT_LOAD_PAGE_CONTENT
  );

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <PageLayout className="search_video_page_container">
      <SearchInputSection
        value={searchValue}
        onChange={handleChangeSearchValue}
      />
      {channelSearchResults.length ? (
        <>
          <div className="search_video_list_container">
            {channelSearchResults.map((item) => {
              return <ContentItem key={item.id} {...item} />;
            })}
          </div>
          {channelSearchResults.length >= LIMIT_LOAD_PAGE_CONTENT && (
            <AncholForPaggination
              className="scroll_pagination_anchor"
              ref={scrollRef}
            />
          )}
        </>
      ) : (
        <Text fontWeight="w500" as="h2" className="search_video_list_empty">
          No Content on this channel.
        </Text>
      )}

      <PageLoader />
    </PageLayout>
  );
};

const PageLoader = () => {
  const isLoadingContent = useAppSelector(getIsLoadingContentSelector);
  const isLoadingChannel = useAppSelector(getIsLoadingChannelsSelector);
  return isLoadingContent || isLoadingChannel ? <Loader /> : null;
};

export default SearchPage;
