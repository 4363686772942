import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getChannelUsers } from 'store/async-actions/chatrooms'
import {
  clearCreateChatRoom,
  getIsFetchingChatRoomsSelector,
} from 'store/reducers/chatrooms'
import { getActiveChannelIdentifierSelector } from 'store/reducers/channels'
import Loader from 'components/common/Loader'

const ChatRoomCreatePage = () => {
  const dispatch = useAppDispatch()

  const channelId = useAppSelector(getActiveChannelIdentifierSelector)

  useEffect(() => {
    if (channelId) {
      dispatch(getChannelUsers({ channelId, pageNo: 0 }))
    }

    return () => {
      dispatch(clearCreateChatRoom())
    }
  }, [dispatch, channelId])

  return (
    <>
      <Outlet />
      <LoaderIndicator />
    </>
  )
}

const LoaderIndicator = () => {
  const isFetching = useAppSelector(getIsFetchingChatRoomsSelector)
  return isFetching ? <Loader /> : null
}

export default ChatRoomCreatePage
