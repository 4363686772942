import React from 'react'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'

import { Chatroom } from '../ChatRoomsPage'
import Text from 'components/common/Text'
import Avatar from 'components/common/Avatar'

import {
  pinningChatRoom,
  unpinningChatRoom,
} from 'store/async-actions/chatrooms'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { getProfileUserIdSelector } from 'store/reducers/profile'

const ChatRoomItem: React.FC<Chatroom> = ({
  title = '',
  description = '',
  onlineCount = 0,
  isPinned,
  id = '',
  logo = '',
}) => {
  const dispatch = useAppDispatch()

  const profileIdent = useAppSelector(getProfileUserIdSelector)

  const handleClickPin = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    //// pin/unpin chanel room
    if (isPinned) {
      dispatch(unpinningChatRoom({ chatRoomId: id, userId: profileIdent! }))
    } else {
      dispatch(pinningChatRoom({ chatRoomId: id, userId: profileIdent! }))
    }
  }

  const classNamePinned = isPinned
    ? 'chatroom_pin_button pinned'
    : 'chatroom_pin_button'

  
  return (
    <Link className="chatroom_item_container" to={String(id)}>
      <Avatar
        className="chat_room_logo_container"
        src={logo}
        alt={`${title} chatroom logo`}
      >
        {title.at(0)?.toUpperCase()}
      </Avatar>
      <div className="content_container">
        <Text className="chatroom_item_title" fontWeight="w700">
          {title}
        </Text>
        <Text className="chatroom_description" fontWeight="w500">
          {description}
        </Text>
      </div>
      <div className="pinned_container">
        <Text className="chatroom_onlinecount" fontWeight="w400">
          {onlineCount} online
        </Text>
        <IconButton className={classNamePinned} onClick={handleClickPin}>
          <PushPinOutlinedIcon className="button_icon" />
        </IconButton>
      </div>
    </Link>
  )
}

export default ChatRoomItem
